import React, { useState, useRef, useEffect } from 'react';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import * as S from './styled';
import '@Toast/styles.css';
import { getActivatedPlans } from '@Services/noar-api';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { Loading } from '../../../Loading';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Text } from '../../../Public';

import select from '../../../../pages/HomePage/HowItWorks/icons/select.png';
import lendoumlivro from '../../../../pages/HomePage/HowItWorks/icons/lendoumlivro.png';
import alunograduacao from '../../../../pages/HomePage/HowItWorks/icons/alunograduacao.png';
import computacaoemnuvem from '../../../../pages/HomePage/HowItWorks/icons/computacaoemnuvem.png';
import freetag from '../../../../pages/HomePage/HowItWorks/icons/free.png';
import Payment from '../../Payment';
import { ORIGIN_UNIT } from '../../../../constants';
import { verifyPurchaseAvailability } from '../../../../services/noar-api';
import { openConfirmationLightbox } from '../../../../store/ducks/global/actions';
import PaymentComponent from '../MpPaymentBrick';
import PaymentStatusComponent from '../MpPaymentStatusBrick';
import { Close } from '@material-ui/icons';

const UnitCard = ({ products, isOpen, onClick, onRequestClose, disableButton, isPublic, planData }) => {
  const [showPayment, handleShowPayment] = useState(false);
  const [paymentId, setPaymentId] = useState();
  const [selectedProductTotalAmount, setSelectedProductTotalAmount] = useState(0);
  const [selectedProductList, setSelectedProductList] = useState([]);
  const [disablePaymentContent, setDisablePaymentContent] = useState(false);
  const { accountPersonalInfo } = useSelector(state => state.global);
  const modalRef = useRef();
  const history = useHistory();
  var dispatch = useDispatch();

  const goToAccessPage = () => {
    history.push('/my/home');
  };

  function closePayment() {
    setPaymentId(null);
    handleShowPayment(false);
    history.push('/shop/unit');
    window.location.reload();
  };

  const handlePublicRedirect = formattedProducts => {
    history.push('/login', { next: '/shop/unit', state: { formattedProducts: formattedProducts } });
  };

  async function handleOrderValidations(selectedProducts) {

    if (Object.keys(planData).length > 0) {
      goToAccessPage();
    }

    if (isPublic) {
      handlePublicRedirect(selectedProducts);
      return;
    }
    setDisablePaymentContent(true);
    let availability = await verifyPurchaseAvailability()
      .then(() => true)
      .catch(() => {
        dispatch(openConfirmationLightbox(
          () => {
            history.push('/shop/unit');
          },
          <>Você excedeu o limite de tentativas recusadas, tente realizar novamente em 24 horas e verifique suas informações.</>,
          'Entendi',
          null,
          null,
          null,
          false,
        ));
      })
    if (!availability) return;

    await accountPersonalInfo
      .showPopupValidationFunction(
        selectedProductTotalAmount,
        selectedProductList,
        setPaymentId,
        handleShowPayment,
        false,
        true,
        setDisablePaymentContent,
        true,
      )
      .then(resp => {
        setDisablePaymentContent(false);
        if (!resp) {
          handleOrder(selectedProducts);
        }
      })
      .catch(() => {
        setDisablePaymentContent(false)
      });
  }

  function handleOrder(selectedProducts) {
    const totalAmount = selectedProducts.reduce((total, selectedProduct) => {
      products.forEach(product => {
        if (selectedProduct.product_id === product.product_id) {
          selectedProduct.price = product.price * selectedProduct.quantity;
        }
      });
      return total + selectedProduct.price;
    }, 0);

    setSelectedProductList(selectedProducts);
    setSelectedProductTotalAmount(totalAmount);
    handleShowPayment(true);
  }

  // useEffect(() => {
  //   function executeActionsFromPublicRedirect() {
  //     handleOrderValidations(history.location.state.formattedProducts);
  //   }

  //   if (history.location.state?.formattedProducts && !disableButton && accountPersonalInfo) {
  //     executeActionsFromPublicRedirect();
  //   }
  // }, [disableButton, history.location.state?.formattedProducts, accountPersonalInfo]);

  // useEffect(() => {
  //   if (paymentId) {
  //     handleShowPayment(false);
  //     dispatch(
  //       openConfirmationLightbox(
  //         () => {
  //           window.location.reload();
  //         },
  //         'Assinatura feita com sucesso!',
  //         'Fechar',
  //         '',
  //         null,
  //         null,
  //         false,
  //       ),
  //     );
  //     setDisablePaymentContent(false);
  //     history.push('/my/home');
  //   }
  // }, [paymentId]);

  useEffect(() => {
    if (selectedProductList.length > 0) {
      handleOrderValidations(selectedProductList);
    }
  }, [selectedProductList])

  useEffect(() => {
    console.log('planData', planData);
  }, [planData]);

  return disablePaymentContent ? (
    <Loading whichLoading={'generic'} />
  ) : (
    <>
      <S.PlansWrapper id="planos">
        <S.ChooseWrapper>
          <S.NichePresentation>
            <S.HeaderWrapper>
              <S.NicheIconsWrapper>
                <S.NicheImage src={freetag} alt="Uma tag escrito free." />
              </S.NicheIconsWrapper>
              <S.NichePresentationTitle>
                <strong>Fila Gratuita</strong>
              </S.NichePresentationTitle>
            </S.HeaderWrapper>
            <S.ChecklistWrapper>
              <p>Utilize de graça!</p><br />
              <S.ChecklistItem>
                <S.ChecklistItemImage src={select} alt="Select marcado." />
                <p>Acesse super computadores de forma gratuita.</p>
              </S.ChecklistItem>
              <S.ChecklistItem>
                <S.ChecklistItemImage src={select} alt="Select marcado." />
                <p>Até 30 minutos de sessão.</p>
              </S.ChecklistItem>
              <S.ChecklistItem>
                <S.ChecklistItemImage src={select} alt="Select marcado." />
                <p>Quer testar a tecnologia?</p>
              </S.ChecklistItem>
            </S.ChecklistWrapper>
            <S.NichePresentationSubTitle>
              <p>
                Utilize nossos supercomputadores de forma gratuita por até 30 minutos. Ideal para quem quer testar a tecnologia e entender como funciona.
              </p>
            </S.NichePresentationSubTitle>
            <Button variant="contained" disabled={Object.keys(planData).length > 0} home_modal={true} onClick={goToAccessPage}>
              {
                Object.keys(planData).length > 0 ? '-' : 'Acessar'
              }
            </Button>
          </S.NichePresentation>
          <S.NichePresentation>
            <S.HeaderWrapper>
              <S.NicheIconsWrapper>
                <S.NicheImage src={alunograduacao} alt="" />
              </S.NicheIconsWrapper>
              <S.NichePresentationTitle>
                <strong>Basic</strong>
              </S.NichePresentationTitle>
            </S.HeaderWrapper>
            <S.ChecklistWrapper>
              <p>O plano mais simples!</p><br />
              <S.ChecklistItem>
                <S.ChecklistItemImage src={select} alt="Select marcado." />
                <p><strong>20 horas</strong> prioritárias totais.*</p>
              </S.ChecklistItem>
              <S.ChecklistItem>
                <S.ChecklistItemImage src={select} alt="Select marcado." />
                <p>Acesso garantido antes do Gratuito!</p>
              </S.ChecklistItem>
              <S.ChecklistItem>
                <S.ChecklistItemImage src={select} alt="Select marcado." />
                <p>3 horas de sessão.</p>
              </S.ChecklistItem>
            </S.ChecklistWrapper>
            <Text>
              <S.PriceText>
                <strong><em>R$89,90 <a>/mês</a></em></strong>
              </S.PriceText>
            </Text>
            {/* TODO: FAZER BOTAO LEVAR PARA LOJA INTERNA */}
            <Button variant="contained" disabled={Object.keys(planData).length > 0 && planData?.plan_type !== 'BASIC' && planData?.plan_type !== 'DEPRIORITIZED_BASIC'} home_modal={true} onClick={() => handleOrderValidations([{ product_id: 49, quantity: 1 }])}>
              {Object.keys(planData).length > 0
                ? planData.plan_type === 'BASIC' || planData.plan_type === 'DEPRIORITIZED_BASIC'
                  ? 'ACESSAR'
                  : '-'
                : 'Assinar'
              }
            </Button>
          </S.NichePresentation>
          <S.NichePresentation>
            <S.HeaderWrapper>
              <S.NicheIconsWrapper>
                <S.NicheImage src={lendoumlivro} alt="Pessoa lendo um livro." />
              </S.NicheIconsWrapper>
              <S.NichePresentationTitle>
                <strong>Plus</strong>
              </S.NichePresentationTitle>
            </S.HeaderWrapper>
            <S.ChecklistWrapper>
              <p>O melhor custo benefício!</p><br />
              <S.ChecklistItem>
                <S.ChecklistItemImage src={select} alt="Select marcado." />
                <p><strong>40 horas</strong> prioritárias totais.*</p>
              </S.ChecklistItem>
              <S.ChecklistItem>
                <S.ChecklistItemImage src={select} alt="Select marcado." />
                <p>Acesso preferencial, acima do plano Basic.</p>
              </S.ChecklistItem>
              <S.ChecklistItem>
                <S.ChecklistItemImage src={select} alt="Select marcado." />
                <p>4 horas de sessão.</p>
              </S.ChecklistItem>
            </S.ChecklistWrapper>
            <Text>
              <S.PriceText>
                <strong><em>R$129,90 <a>/mês</a></em></strong>
              </S.PriceText>
            </Text>
            <Button variant="contained" disabled={Object.keys(planData).length > 0 && planData?.plan_type !== 'PRIORITY' && planData?.plan_type !== 'DEPRIORITIZED_PRIORITY'} home_modal={true} onClick={() => handleOrderValidations([{ product_id: 50, quantity: 1 }])}>
              {Object.keys(planData).length > 0
                ? planData.plan_type === 'PRIORITY' || planData.plan_type === 'DEPRIORITIZED_PRIORITY'
                  ? 'ACESSAR'
                  : '-'
                : 'Assinar'
              }
            </Button>
          </S.NichePresentation>
          <S.NichePresentation>
            <S.HeaderWrapper>
              <S.NicheIconsWrapper>
                <S.NicheImage src={computacaoemnuvem} alt="" />
              </S.NicheIconsWrapper>
              <S.NichePresentationTitle>
                <strong>ULTRA</strong>
              </S.NichePresentationTitle>
            </S.HeaderWrapper>
            <S.ChecklistWrapper>
              <p>O seu melhor plano!</p><br />
              <S.ChecklistItem>
                <S.ChecklistItemImage src={select} alt="Select marcado." />
                <p><strong>60 horas</strong> prioritárias totais.*</p>
              </S.ChecklistItem>
              <S.ChecklistItem>
                <S.ChecklistItemImage src={select} alt="Select marcado." />
                <p>Acesso exclusivo com máxima prioridade!</p>
              </S.ChecklistItem>
              <S.ChecklistItem>
                <S.ChecklistItemImage src={select} alt="Select marcado." />
                <p>5 horas de sessão.</p>
              </S.ChecklistItem>
            </S.ChecklistWrapper>
            <Text>
              <S.PriceText>
                <strong><em>R$169,90 <a>/mês</a></em></strong>
              </S.PriceText>
            </Text>
            <Button variant="contained" disabled={Object.keys(planData).length > 0 && planData?.plan_type !== 'ULTRA' && planData?.plan_type !== 'DEPRIORITIZED_ULTRA'} home_modal={true} onClick={() => handleOrderValidations([{ product_id: 51, quantity: 1 }])}>
              {Object.keys(planData).length > 0
                ? planData.plan_type === 'ULTRA' || planData.plan_type === 'DEPRIORITIZED_ULTRA'
                  ? 'ACESSAR'
                  : '-'
                : 'Assinar'
              }
            </Button>
          </S.NichePresentation>
        </S.ChooseWrapper>
        <S.Main>
          <S.Observation>
            <p>*Depois que as horas prioritárias acabarem, você ainda pode utilizar o UNIT, mas com menos prioridade.</p>
            <p><strong>Prioridade na fila:</strong> Gratuito ➔ Basic ➔ Plus ➔ Ultra ➔ Basic Prioritário ➔ Plus Prioritário ➔ Ultra Prioritário</p>
          </S.Observation>
        </S.Main>
      </S.PlansWrapper >
      {showPayment && !disablePaymentContent ? (
        <S.PaymentBrickBackGround showPayment={showPayment} onClick={()=>{}}>
          <S.PaymentBrickWrapper showPayment={showPayment}>
            <S.CloseButton>
              <Close onClick={closePayment} />
            </S.CloseButton>
            {!paymentId ? (
              <PaymentComponent
                amount={selectedProductTotalAmount}
                selectedProductList={selectedProductList}
                setPaymentId={setPaymentId}
                handleShowPayment={handleShowPayment}
                onlyPix={false}
                setDisablePaymentContent={setDisablePaymentContent}
                recurrent={false}
                discountVoucher={undefined}
              />
            ) : (
              <PaymentStatusComponent paymentId={paymentId} productLink="/shop/unit" />
            )}
          </S.PaymentBrickWrapper>
        </S.PaymentBrickBackGround>
      ) : null}
    </>
  );
};

export default UnitCard;
