import React, { useEffect, useRef, useState } from 'react';
import { useLocation } from 'react-router-dom';

import { CLIENT_TYPES } from '@Constants';
import Link from './Link';
import * as S from './styled';

import * as sessionManager from '@Utils/session-manager';
import homeImg from '../../../assets/home.svg';
import homeFillImg from '../../../assets/home-fill.svg';
import planosImg from '../../../assets/planos.svg';
import planosFillImg from '../../../assets/planos-fill.svg';
import historicoImg from '../../../assets/historico.svg';
import historicoFillImg from '../../../assets/historico-fill.svg';
import faqImg from '../../../assets/faq.svg';
import faqFillImg from '../../../assets/faq-fill.svg';
import downloadImg from '../../../assets/download-icon.svg';
import downloadImgFill from '../../../assets/download-icon-fill.svg';
import { desktopMinSize } from '@Styles/mixins';
import { useUserRole } from '../../../context/UserRoleContext';
import NOAR_LOGO_COMPLETE from '../../../assets/NOAR_LOGO_COMPLETE.png';
import Cookies from 'js-cookie';
import { SESSION_COOKIE_NAME } from '../../../constants';

const NavView = ({ containerHeight, isOpen, setIsOpen }) => {

  const [activeLink, setActiveLink] = useState(0);
  const linkRefs = useRef([]);
  const location = useLocation();
  const { clientType } = useUserRole();
  const { activePlans } = JSON.parse(Cookies.get(SESSION_COOKIE_NAME));
  const [hasActiveUnitPlan, setHasActiveUnitPlan] = useState(false);
  const afterTop = activeLink?.offsetTop;
  const afterLeft = activeLink?.offsetLeft;
  const afterHeight = activeLink?.clientHeight;

  const setLinkRef = link => linkRefs.current.push(link);

  const links = [
    ...[
      {
        to: '/my/home',
        src: `${location.pathname === '/my/home' ? homeFillImg : homeImg}`,
        color: `${location.pathname === '/my/home' ? '#426FE5' : '#FFFFFF'}`,
        label: clientType === 'B2B_ADM' ? 'Home' : 'Meu Computador',
        isMobile: true,
      }
    ],
    ...(clientType === "B2B_ADM"
      ? [
        {
          to: '/admin/images',
          src: `${location.pathname === '/admin/images' ? homeFillImg : homeImg}`,
          color: `${location.pathname === '/admin/images' ? '#426FE5' : '#FFFFFF'}`,
          label: 'Imagens',
          isMobile: true,
        },
      ] : []
    ),
    ...(clientType === "B2C"
      ? [
        ...(hasActiveUnitPlan === true ? [{
          to: '/use/occasional/unit',
          src: `${location.pathname === '/use/occasional/unit' ? homeFillImg : homeImg}`,
          color: `${location.pathname === '/use/occasional/unit' ? '#426FE5' : '#FFFFFF'}`,
          label: 'Unit',
          isMobile: true,
        }] : [])
      ] : []),
    ...(sessionManager.getClientType() !== CLIENT_TYPES.NOAR_PERSONAL && clientType === "B2C"
      ? [
        {
          to: '/shop/unit',
          src: `${location.pathname === '/shop/unit' ? planosFillImg : planosImg}`,
          color: `${location.pathname === '/shop/unit' ? '#426FE5' : '#FFFFFF'}`,
          label: 'Loja',
          isMobile: true,
        },
      ]
      : []),
    ...[
      {
        to: '/my/sessions',
        src: `${location.pathname === '/my/sessions' ? historicoFillImg : historicoImg}`,
        color: `${location.pathname === '/my/sessions' ? '#426FE5' : '#FFFFFF'}`,
        label: 'Histórico',
        isMobile: true,
      },
      ...(clientType === "B2C")
        ? [{
          to: '/support',
          src: `${location.pathname === '/support' ? faqFillImg : faqImg}`,
          color: `${location.pathname === '/support' ? '#426FE5' : '#FFFFFF'}`,
          label: 'Suporte',
          isMobile: true,
        }]
        : [],
    ],
    ...(clientType !== "B2B_ADM")
      ? [{
        to: '/my/download',
        src: `${location.pathname === '/my/download' ? downloadImgFill : downloadImg}`,
        color: `${location.pathname === '/my/download' ? '#426FE5' : '#FFFFFF'}`,
        label: 'Download',
        isMobile: true,
      }] : [],
  ];

  useEffect(
    function () {
      setActiveLink(linkRefs.current[links.findIndex(link => link.to === location.pathname)]);
    },
    [linkRefs, links, location, setActiveLink, setIsOpen],
  );

  useEffect(
    function () {
      if (location) setIsOpen(false);
    },
    [location, setIsOpen],
  );

  useEffect(() => {
    async function validateUnitPlan() {
      const validPlans = ["MONTHLY"];
      for (let i = 0; i < activePlans.length; i++) {
        if (validPlans.includes(activePlans[i].signature_type)) {
          setHasActiveUnitPlan(true);
        }
      }
    }
    validateUnitPlan();
  }, [activePlans]);

  return (
    <S.Div>
      <S.Logo alt={''} src={NOAR_LOGO_COMPLETE} width={75} height={42} />
      <S.Nav
        containerHeight={containerHeight}
        isOpen={isOpen}
        afterTop={afterTop}
        afterLeft={afterLeft}
        aftertHeight={afterHeight}
      >
        {links.map((props, index) => {
          if (window.innerWidth >= desktopMinSize || (window.innerWidth < desktopMinSize && props.isMobile)) {
            return <Link key={index} ref={setLinkRef} {...props} />;
          }
        })}
      </S.Nav>
    </S.Div>
  );
};

function Nav({ containerHeight, isOpen, setIsOpen }) {
  return <NavView containerHeight={containerHeight} isOpen={isOpen} setIsOpen={setIsOpen} />;
}

export default Nav;
