import { io } from 'socket.io-client';

import { API_NOAR_BASE, SESSION_DEFAULT_SIZE } from '@Constants';
import {
  SessionExpired,
  RegisterError,
  CreateInstanceError,
  RegisterConfirmationError,
  B2bActionError,
  ResendRegisterConfirmationEmailError,
  CreateNpsAnswer,
  UseCoupon,
  SendTermsAccepted,
  SaveUserInfo,
  UpdatePassword,
  PlansFormError,
  PurchaseBlockedFor24Hours,
} from '@Errors';
import * as sessionManager from '@Utils/session-manager';

export async function login(user_name, password, token) {
  const myHeaders = new Headers();
  myHeaders.append('Content-Type', 'application/json');
  myHeaders.append('Accept', 'application/json');

  let url = `${API_NOAR_BASE}/clients/login`;

  const response = await fetch(url, {
    method: 'post',
    headers: myHeaders,
    mode: 'cors',
    body: JSON.stringify({
      user_name,
      password,
      token,
      origin: 'WEB',
    }),
  });

  const responseBody = await response.json();

  if (response.ok) {
    return responseBody;
  } else {
    throw new RegisterError(responseBody.message, responseBody.status);
  }
}

export async function register(email, password, email_sending_approval, name, referralVoucher) {
  const myHeaders = new Headers();
  myHeaders.append('Content-Type', 'application/json');
  myHeaders.append('Accept', 'application/json');

  const response = await fetch(`${API_NOAR_BASE}/clients/register`, {
    method: 'post',
    headers: myHeaders,
    mode: 'cors',
    body: JSON.stringify({
      email,
      password,
      name,
      coupon: referralVoucher,
      email_sending_approval,
    }),
  });

  if (response.ok) {
    return null;
  } else {
    const responseBody = await response.json();
    throw new RegisterError(responseBody.message);
  }
}

export async function sendRegisterConfirmationCode(email, code) {
  const myHeaders = new Headers();
  myHeaders.append('Content-Type', 'application/json');
  myHeaders.append('Accept', 'application/json');

  const response = await fetch(`${API_NOAR_BASE}/clients/register/confirm?code=${code}&user_name=${email}`, {
    method: 'post',
    headers: myHeaders,
    mode: 'cors',
  });
  if (response.ok) {
    return null;
  } else {
    const responseBody = await response.json();
    throw new RegisterConfirmationError(responseBody.message);
  }
}

export async function resendRegisterConfirmationEmail(email, code) {
  const myHeaders = new Headers();
  myHeaders.append('Content-Type', 'application/json');
  myHeaders.append('Accept', 'application/json');

  const response = await fetch(`${API_NOAR_BASE}/clients/register/resend?user_name=${email}`, {
    method: 'post',
    headers: myHeaders,
    mode: 'cors',
  });
  if (response.ok) {
    return null;
  } else {
    const responseBody = await response.json();
    throw new ResendRegisterConfirmationEmailError(responseBody.message);
  }
}

export async function preRegister(objPreRegister) {
  const myHeaders = new Headers();
  myHeaders.append('Content-Type', 'application/json');
  myHeaders.append('Accept', 'application/json');

  const response = await fetch(`${API_NOAR_BASE}/clients/preregister`, {
    method: 'post',
    headers: myHeaders,
    mode: 'cors',
    body: JSON.stringify(objPreRegister),
  });

  if (response.ok) {
    return null;
  } else {
    const responseBody = await response.json();
    throw new RegisterError(responseBody.message);
  }
}

export async function getDevices() {
  try {
    const myHeaders = new Headers();
    myHeaders.append('Content-Type', 'application/json');
    myHeaders.append('Accept', 'application/json');
    const response = await fetch(`${API_NOAR_BASE}/clients/preregister/devices`, {
      method: 'get',
      headers: myHeaders,
      mode: 'cors',
    });

    return await response.json();
  } catch (error) {
    return null;
  }
}

export async function getQueueStatus(queue_type = 'UNIT') {
  const jwt = sessionManager.getJwt();

  if (jwt) {
    try {
      const myHeaders = new Headers();
      myHeaders.append('Content-Type', 'application/json');
      myHeaders.append('Accept', 'application/json');
      myHeaders.append('Authorization', `Bearer ${jwt}`);

      const response = await fetch(`${API_NOAR_BASE}/queue/instances/status?queue_type=${queue_type.toUpperCase()}`, {
        method: 'get',
        headers: myHeaders,
        mode: 'cors',
      });
      return await response.json();
    } catch (error) {
      console.error('Error in getInstance', error);
      return null;
    }
  } else {
    throw new SessionExpired();
  }
}

export async function getInstance() {
  const jwt = sessionManager.getJwt();

  if (jwt) {
    try {
      const myHeaders = new Headers();
      myHeaders.append('Content-Type', 'application/json');
      myHeaders.append('Accept', 'application/json');
      myHeaders.append('Authorization', `Bearer ${jwt}`);

      const response = await fetch(`${API_NOAR_BASE}/instances`, {
        method: 'get',
        headers: myHeaders,
        mode: 'cors',
      });
      sessionManager.checkStatusResponse(response);
      return await response.json();
    } catch (error) {
      console.error('Error in getInstance', error);
      return null;
    }
  } else {
    throw new SessionExpired();
  }
}

export async function createInstance(image, creation_type, host_id = null, use_type = "USER_USE") {
  const jwt = sessionManager.getJwt();

  if (jwt) {
    try {
      const myHeaders = new Headers();
      myHeaders.append('Content-Type', 'application/json');
      myHeaders.append('Accept', 'application/json');
      myHeaders.append('Authorization', `Bearer ${jwt}`);

      const response = await fetch(`${API_NOAR_BASE}/instances?creation_type=${creation_type}`, {
        method: 'post',
        headers: myHeaders,
        mode: 'cors',
        body: JSON.stringify({
          image,
          host_id,
          use_type,
        }),
      });
      sessionManager.checkStatusResponse(response);
      const responseBody = await response.json();
      if (response.ok) {
        return responseBody;
      } else {
        throw new CreateInstanceError(responseBody.message);
      }
    } catch (error) {
      console.error('Error in createInstance', error);
      throw error;
    }
  } else {
    throw new SessionExpired();
  }
}

export async function confirmInstance() {
  const jwt = sessionManager.getJwt();

  if (jwt) {
    try {
      const myHeaders = new Headers();
      myHeaders.append('Content-Type', 'application/json');
      myHeaders.append('Accept', 'application/json');
      myHeaders.append('Authorization', `Bearer ${jwt}`);

      const response = await fetch(`${API_NOAR_BASE}/queue/instances/confirm`, {
        method: 'post',
        headers: myHeaders,
        mode: 'cors',
      });
      sessionManager.checkStatusResponse(response);
      const responseBody = await response.json();
      return responseBody;
    } catch (error) {
      console.error('Error in createInstance', error);
      throw error;
    }
  } else {
    throw new SessionExpired();
  }
}

export async function instanceSocket(callbackHours, callbackStatus, callbackPosition, callbackPersonalStatus) {
  const jwt = sessionManager.getJwt();

  if (jwt) {
    try {
      const socket = io(API_NOAR_BASE, {
        transports: ['websocket'],
      });
      socket.on('get-token', callback => {
        callback(jwt);
      });
      socket.on('/user/client/hours', hours => {
        callbackHours(hours);
      });
      socket.on('/user/instance/status', status => {
        callbackStatus(status);
      });
      socket.on('/user/queue/position', position => {
        callbackPosition(position);
      });
      socket.on('/user/personal/status', personalStatus => {
        callbackPersonalStatus(personalStatus);
      });
    } catch (error) {
      console.error('Error in instanceSocket', error);
      return null;
    }
  } else {
    throw new SessionExpired();
  }
}

export async function queueInitInstance(image, creation_type = 'UNIT') {
  const jwt = sessionManager.getJwt();

  if (jwt) {
    try {
      const myHeaders = new Headers();
      myHeaders.append('Content-Type', 'application/json');
      myHeaders.append('Accept', 'application/json');
      myHeaders.append('Authorization', `Bearer ${jwt}`);

      const response = await fetch(`${API_NOAR_BASE}/queue/instances`, {
        method: 'post',
        headers: myHeaders,
        mode: 'cors',
        body: JSON.stringify({
          image,
          creation_type: creation_type,
        }),
      });
      sessionManager.checkStatusResponse(response);
      const responseBody = await response?.json();
      if (!response.ok) {
        throw new CreateInstanceError(responseBody.message);
      }
      return responseBody;
    } catch (error) {
      console.error('Error in get queue position', error);
      throw error;
    }
  } else {
    throw new SessionExpired();
  }
}

export async function isOnQueue(queue_type = 'UNIT') {
  const jwt = sessionManager.getJwt();

  if (jwt) {
    try {
      const myHeaders = new Headers();
      myHeaders.append('Content-Type', 'application/json');
      myHeaders.append('Accept', 'application/json');
      myHeaders.append('Authorization', `Bearer ${jwt}`);

      const response = await fetch(`${API_NOAR_BASE}/queue/instances?queue_type=${queue_type}`, {
        method: 'get',
        headers: myHeaders,
        mode: 'cors',
      });
      sessionManager.checkStatusResponse(response);
      return await response.json();
    } catch (error) {
      console.error('Error in get queue position', error);
      return null;
    }
  } else {
    throw new SessionExpired();
  }
}

export async function leaveQueue() {
  const jwt = sessionManager.getJwt();

  if (jwt) {
    try {
      const myHeaders = new Headers();
      myHeaders.append('Content-Type', 'application/json');
      myHeaders.append('Accept', 'application/json');
      myHeaders.append('Authorization', `Bearer ${jwt}`);

      const response = await fetch(`${API_NOAR_BASE}/queue/instances/disconnect`, {
        method: 'post',
        headers: myHeaders,
        mode: 'cors',
      });
      sessionManager.checkStatusResponse(response);
      return response;
    } catch (error) {
      console.error('Error on leave queue', error);
      return null;
    }
  } else {
    throw new SessionExpired();
  }
}

export async function stopInstance(instanceId) {
  const jwt = sessionManager.getJwt();

  if (jwt) {
    try {
      const myHeaders = new Headers();
      myHeaders.append('Content-Type', 'application/json');
      myHeaders.append('Accept', 'application/json');
      myHeaders.append('Authorization', `Bearer ${jwt}`);

      const response = await fetch(`${API_NOAR_BASE}/instances/${instanceId}/stop`, {
        method: 'post',
        headers: myHeaders,
        mode: 'cors',
      });
      sessionManager.checkStatusResponse(response);
    } catch (error) {
      console.error('Error in stopInstance', error);
      return null;
    }
  } else {
    throw new SessionExpired();
  }
}

export async function sendAuthCode(instanceId, authenticationCode) {
  const jwt = sessionManager.getJwt();

  if (jwt) {
    try {
      const myHeaders = new Headers();
      myHeaders.append('Content-Type', 'application/json');
      myHeaders.append('Accept', 'application/json');
      myHeaders.append('Authorization', `Bearer ${jwt}`);

      const response = await fetch(`${API_NOAR_BASE}/instances/${instanceId}/auth`, {
        method: 'post',
        headers: myHeaders,
        mode: 'cors',
        body: JSON.stringify({
          code: authenticationCode,
        }),
      });
      sessionManager.checkStatusResponse(response);
      return await response.json();
    } catch (error) {
      console.error('Error in sendAuthCode', error);
      return null;
    }
  } else {
    throw new SessionExpired();
  }
}

export async function getAMIs(selectedPlan) {
  const jwt = sessionManager.getJwt();

  if (jwt) {
    try {
      const myHeaders = new Headers();
      myHeaders.append('Content-Type', 'application/json');
      myHeaders.append('Accept', 'application/json');
      myHeaders.append('Authorization', `Bearer ${jwt}`);

      var params = selectedPlan === null ? '' : `product_type=${selectedPlan.toUpperCase()}`;

      const response = await fetch(`${API_NOAR_BASE}/images?${params}`, {
        method: 'get',
        headers: myHeaders,
        mode: 'cors',
      });
      sessionManager.checkStatusResponse(response);
      return await response.json();
    } catch (error) {
      console.error('Error in getInstance', error);
      return null;
    }
  } else {
    throw new SessionExpired();
  }
}

export async function getClientHours() {
  const jwt = sessionManager.getJwt();

  if (jwt) {
    try {
      const myHeaders = new Headers();
      myHeaders.append('Content-Type', 'application/json');
      myHeaders.append('Accept', 'application/json');
      myHeaders.append('Authorization', `Bearer ${jwt}`);

      const response = await fetch(`${API_NOAR_BASE}/clients/hours`, {
        method: 'get',
        headers: myHeaders,
        mode: 'cors',
      });
      sessionManager.checkStatusResponse(response);
      return await response.json();
    } catch (error) {
      console.error('Error in getClientHours', error);
      return null;
    }
  } else {
    throw new SessionExpired();
  }
}

export async function getHasToShowNpsForm(origin) {
  const jwt = sessionManager.getJwt();

  if (jwt) {
    try {
      const myHeaders = new Headers();
      myHeaders.append('Content-Type', 'application/json');
      myHeaders.append('Accept', 'application/json');
      myHeaders.append('Authorization', `Bearer ${jwt}`);

      const response = await fetch(`${API_NOAR_BASE}/form/nps/validate?origin=${origin}`, {
        method: 'post',
        headers: myHeaders,
        mode: 'cors',
      });
      sessionManager.checkStatusResponse(response);
      return await response.json();
    } catch (error) {
      console.error('Error in getHasToShowNpsForm', error);
      return null;
    }
  } else {
    throw new SessionExpired();
  }
}

export async function getHasToPrivacyForm() {
  const jwt = sessionManager.getJwt();

  if (jwt) {
    try {
      const myHeaders = new Headers();
      myHeaders.append('Content-Type', 'application/json');
      myHeaders.append('Accept', 'application/json');
      myHeaders.append('Authorization', `Bearer ${jwt}`);

      const response = await fetch(`${API_NOAR_BASE}/clients/privacy/validate`, {
        method: 'post',
        headers: myHeaders,
        mode: 'cors',
      });
      sessionManager.checkStatusResponse(response);
      return await response.json();
    } catch (error) {
      console.error('Error in getHasToPrivacyForm', error);
      return null;
    }
  } else {
    throw new SessionExpired();
  }
}

export async function addCoupon(couponCode) {
  const jwt = sessionManager.getJwt();

  if (jwt) {
    try {
      const myHeaders = new Headers();
      myHeaders.append('Content-Type', 'application/json');
      myHeaders.append('Accept', 'application/json');
      myHeaders.append('Authorization', `Bearer ${jwt}`);

      const response = await fetch(`${API_NOAR_BASE}/coupons/${couponCode}/use`, {
        method: 'put',
        headers: myHeaders,
        mode: 'cors',
      });
      sessionManager.checkStatusResponse(response);
      if (!response.ok) {
        const responseBody = await response.json();
        throw new UseCoupon(responseBody.message);
      }
    } catch (error) {
      console.error('Error in useCoupon', error);
      throw error;
    }
  } else {
    throw new SessionExpired();
  }
}

export async function sendTermsAccepted() {
  const jwt = sessionManager.getJwt();

  if (jwt) {
    try {
      const myHeaders = new Headers();
      myHeaders.append('Content-Type', 'application/json');
      myHeaders.append('Accept', 'application/json');
      myHeaders.append('Authorization', `Bearer ${jwt}`);

      const response = await fetch(`${API_NOAR_BASE}/clients/privacy`, {
        method: 'post',
        headers: myHeaders,
        mode: 'cors',
        body: JSON.stringify({
          privacy_confirmation: true,
        }),
      });
      sessionManager.checkStatusResponse(response);
      if (!response.ok) {
        const responseBody = await response.json();
        throw new SendTermsAccepted(responseBody.message);
      }
    } catch (error) {
      console.error('Error in SendTermsAccepted', error);
      throw error;
    }
  } else {
    throw new SessionExpired();
  }
}

export async function sendNpsAnswer(scoreValue, reason, response_type, origin, cancel_reason) {
  const jwt = sessionManager.getJwt();

  if (jwt) {
    try {
      const myHeaders = new Headers();
      myHeaders.append('Content-Type', 'application/json');
      myHeaders.append('Accept', 'application/json');
      myHeaders.append('Authorization', `Bearer ${jwt}`);

      const response = await fetch(`${API_NOAR_BASE}/form/nps?origin=${origin}`, {
        method: 'post',
        headers: myHeaders,
        mode: 'cors',
        body: JSON.stringify({
          nota: scoreValue,
          motivo: reason,
          tipo_resposta: response_type,
          origem_resposta: origin,
          motivo_cancelamento: cancel_reason
        }),
      });
      sessionManager.checkStatusResponse(response);
      const responseBody = await response.json();
      if (response.ok) {
        return responseBody;
      } else {
        throw new CreateNpsAnswer(responseBody.message);
      }
    } catch (error) {
      console.error('Error in sendNpsAnswer', error);
      throw error;
    }
  } else {
    throw new SessionExpired();
  }
}

export async function getSessions(page_number = 0, page_size = SESSION_DEFAULT_SIZE, sort_by, order_by) {
  const jwt = sessionManager.getJwt();

  if (jwt) {
    try {
      const myHeaders = new Headers();
      myHeaders.append('Content-Type', 'application/json');
      myHeaders.append('Accept', 'application/json');
      myHeaders.append('Authorization', `Bearer ${jwt}`);

      const queryParams = { page_number, page_size, sort_by, order_by };
      Object.keys(queryParams).forEach(key => queryParams[key] === undefined && delete queryParams[key]);

      const response = await fetch(`${API_NOAR_BASE}/clients/sessions?${new URLSearchParams(queryParams)}`, {
        method: 'get',
        headers: myHeaders,
        mode: 'cors',
      });
      sessionManager.checkStatusResponse(response);

      return await response.json();
    } catch (error) {
      console.error('Error in get Sessions', error);
      return null;
    }
  } else {
    throw new SessionExpired();
  }
}

export async function passwordForgot(email) {
  const myHeaders = new Headers();
  myHeaders.append('Accept', 'application/json');

  let url = `${API_NOAR_BASE}/clients/password/forgot`;
  url = `${url}?user_name=${email}`;

  const response = await fetch(url, {
    method: 'post',
    headers: myHeaders,
    mode: 'cors',
  });

  if (response.ok) {
    return undefined;
  } else {
    const responseBody = await response.json();
    throw new RegisterError(responseBody.message);
  }
}

export async function sendPasswordForgot(email, code, password) {
  const myHeaders = new Headers();
  myHeaders.append('Content-Type', 'application/json');
  myHeaders.append('Accept', 'application/json');

  let url = `${API_NOAR_BASE}/clients/password/forgot/confirm`;

  const response = await fetch(url, {
    method: 'post',
    headers: myHeaders,
    mode: 'cors',
    body: JSON.stringify({
      user_name: email,
      code,
      password,
    }),
  });

  if (response.ok) {
    return undefined;
  } else {
    const responseBody = await response.json();
    throw new RegisterError(responseBody.message);
  }
}

export async function restartInstance(instanceId) {
  const jwt = sessionManager.getJwt();

  if (jwt) {
    try {
      const myHeaders = new Headers();
      myHeaders.append('Content-Type', 'application/json');
      myHeaders.append('Accept', 'application/json');
      myHeaders.append('Authorization', `Bearer ${jwt}`);

      let url = `${API_NOAR_BASE}/instances/${instanceId}/reboot`;

      const response = await fetch(url, {
        method: 'post',
        headers: myHeaders,
        mode: 'cors',
      });
      sessionManager.checkStatusResponse(response);
    } catch (error) {
      console.error('Error in restartInstance', error);
      return null;
    }
  } else {
    throw new SessionExpired();
  }
}

export async function getProducts() {
  const jwt = sessionManager.getJwt();

  if (jwt) {
    try {
      const myHeaders = new Headers();
      myHeaders.append('Content-Type', 'application/json');
      myHeaders.append('Accept', 'application/json');
      myHeaders.append('Authorization', `Bearer ${jwt}`);

      const response = await fetch(`${API_NOAR_BASE}/products`, {
        method: 'get',
        headers: myHeaders,
        mode: 'cors',
      });
      sessionManager.checkStatusResponse(response);
      return await response.json();
    } catch (error) {
      console.error('Error in getProducts', error);
      return null;
    }
  } else {
    throw new SessionExpired();
  }
}

export async function getProductsPublic() {
  try {
    const myHeaders = new Headers();
    myHeaders.append('Content-Type', 'application/json');
    myHeaders.append('Accept', 'application/json');

    const response = await fetch(`${API_NOAR_BASE}/products/public`, {
      method: 'get',
      headers: myHeaders,
      mode: 'cors',
    });
    sessionManager.checkStatusResponse(response);
    return await response.json();
  } catch (error) {
    console.error('Error in getProducts', error);
    return null;
  }
}

export async function getPlans() {
  const jwt = sessionManager.getJwt();

  if (jwt) {
    try {
      const myHeaders = new Headers();
      myHeaders.append('Content-Type', 'application/json');
      myHeaders.append('Accept', 'application/json');
      myHeaders.append('Authorization', `Bearer ${jwt}`);

      const response = await fetch(`${API_NOAR_BASE}/plans`, {
        method: 'get',
        headers: myHeaders,
        mode: 'cors',
      });
      sessionManager.checkStatusResponse(response);
      return await response.json();
    } catch (error) {
      console.error('Error in getPlans', error);
      return null;
    }
  } else {
    throw new SessionExpired();
  }
}

export async function getActivatedPlans() {
  const jwt = sessionManager.getJwt();

  if (jwt) {
    try {
      const myHeaders = new Headers();
      myHeaders.append('Content-Type', 'application/json');
      myHeaders.append('Accept', 'application/json');
      myHeaders.append('Authorization', `Bearer ${jwt}`);

      const response = await fetch(`${API_NOAR_BASE}/plans?only_activate=true`, {
        method: 'get',
        headers: myHeaders,
        mode: 'cors',
      });
      sessionManager.checkStatusResponse(response);
      return await response.json();
    } catch (error) {
      console.error('Error in getActivatedPlans', error);
      return null;
    }
  } else {
    throw new SessionExpired();
  }
}

export async function getPersonalPlan() {
  const jwt = sessionManager.getJwt();

  if (jwt) {
    try {
      const myHeaders = new Headers();
      myHeaders.append('Content-Type', 'application/json');
      myHeaders.append('Accept', 'application/json');
      myHeaders.append('Authorization', `Bearer ${jwt}`);

      const response = await fetch(`${API_NOAR_BASE}/plans/personal?only_activate=true`, {
        method: 'get',
        headers: myHeaders,
        mode: 'cors',
      });
      sessionManager.checkStatusResponse(response);
      return await response.json();
    } catch (error) {
      console.error('Error in getActivatedPlans', error);
      return null;
    }
  } else {
    throw new SessionExpired();
  }
}

export async function buyProducts(body) {
  const jwt = sessionManager.getJwt();
  if (jwt) {
    try {
      const myHeaders = new Headers();
      myHeaders.append('Content-Type', 'application/json');
      myHeaders.append('Accept', 'application/json');
      myHeaders.append('Authorization', `Bearer ${jwt}`);

      const response = await fetch(`${API_NOAR_BASE}/payments`, {
        method: 'post',
        headers: myHeaders,
        mode: 'cors',
        body: JSON.stringify(body),
      });
      sessionManager.checkStatusResponse(response);
      return await response.json();
    } catch (error) {
      console.error('Error in buyProducts', error);
      return null;
    }
  } else {
    throw new SessionExpired();
  }
}

export async function generateMPOrder(products) {
  const jwt = sessionManager.getJwt();
  if (jwt) {
    const myHeaders = new Headers();
    myHeaders.append('Content-Type', 'application/json');
    myHeaders.append('Accept', 'application/json');
    myHeaders.append('Authorization', `Bearer ${jwt}`);

    const payload = {
      order_items: [...products],
    };

    const response = await fetch(`${API_NOAR_BASE}/payments/external`, {
      method: 'post',
      headers: myHeaders,
      mode: 'cors',
      body: JSON.stringify(payload),
    });

    const responseBody = await response.json();
    if (!response.ok) {
      console.error('Error in buyProducts', responseBody?.message);
    }
    return responseBody;
  } else {
    throw new SessionExpired();
  }
}

export async function confirmBuyProducts(body, ordem) {
  const jwt = sessionManager.getJwt();
  if (jwt) {
    try {
      const myHeaders = new Headers();
      myHeaders.append('Content-Type', 'application/json');
      myHeaders.append('Accept', 'application/json');
      myHeaders.append('Authorization', `Bearer ${jwt}`);

      console.log(body);

      const response = await fetch(`${API_NOAR_BASE}/payments/${ordem}/pay`, {
        method: 'post',
        headers: myHeaders,
        mode: 'cors',
        body: JSON.stringify(body),
      });
      sessionManager.checkStatusResponse(response);
      return await response.json();
    } catch (error) {
      console.error('Error in confirmBuyProducts', error);
      return null;
    }
  } else {
    throw new SessionExpired();
  }
}

export async function getStates() {
  try {
    const response = await fetch(`https://servicodados.ibge.gov.br/api/v1/localidades/estados`, {
      method: 'get',
    });

    return await response.json();
  } catch (error) {
    return null;
  }
}

export async function getAddressByCep(cep) {
  try {
    const response = await fetch(`https://viacep.com.br/ws/${cep}/json/`, {
      method: 'get',
    });

    return await response.json();
  } catch (error) {
    throw error;
  }
}

export async function getUserInfo() {
  const jwt = sessionManager.getJwt();

  if (jwt) {
    try {
      const myHeaders = new Headers();
      myHeaders.append('Content-Type', 'application/json');
      myHeaders.append('Accept', 'application/json');
      myHeaders.append('Authorization', `Bearer ${jwt}`);

      const response = await fetch(`${API_NOAR_BASE}/clients`, {
        method: 'get',
        headers: myHeaders,
        mode: 'cors',
      });
      sessionManager.checkStatusResponse(response);
      return await response.json();
    } catch (error) {
      console.error('Error in getUserInfo', error);
      return null;
    }
  } else {
    throw new SessionExpired();
  }
}

export async function saveUserInfo(name, email_sending_approval, address, phone, document) {
  const jwt = sessionManager.getJwt();
  const body = { name, email_sending_approval, personal_information: { address, phone, cpf: document } };

  if (jwt) {
    try {
      const myHeaders = new Headers();
      myHeaders.append('Content-Type', 'application/json');
      myHeaders.append('Accept', 'application/json');
      myHeaders.append('Authorization', `Bearer ${jwt}`);

      const response = await fetch(`${API_NOAR_BASE}/clients`, {
        method: 'put',
        headers: myHeaders,
        mode: 'cors',
        body: JSON.stringify(body),
      });
      sessionManager.checkStatusResponse(response);
      if (!response.ok) {
        const responseBody = await response.json();
        throw new SaveUserInfo(responseBody.message);
      }
    } catch (error) {
      console.error('Error in saveUserInfo', error);
      throw error;
    }
  } else {
    throw new SessionExpired();
  }
}

export async function updatePassword(previous_password, proposed_password) {
  const jwt = sessionManager.getJwt();
  const body = { previous_password, proposed_password };

  if (jwt) {
    try {
      const myHeaders = new Headers();
      myHeaders.append('Content-Type', 'application/json');
      myHeaders.append('Accept', 'application/json');
      myHeaders.append('Authorization', `Bearer ${jwt}`);

      const response = await fetch(`${API_NOAR_BASE}/clients/password`, {
        method: 'put',
        headers: myHeaders,
        mode: 'cors',
        body: JSON.stringify(body),
      });

      if (!response.ok) {
        const responseBody = await response.json();
        throw new UpdatePassword(responseBody.message);
      }
    } catch (error) {
      console.error('Error in updatePassword', error);
      throw error;
    }
  } else {
    throw new SessionExpired();
  }
}

export async function getBlogPosts(params) {
  const search = params
    ? `?${Object.entries(params)
      .map(param => param.join('='))
      .join('&')}`
    : '';

  try {
    const myHeaders = new Headers();
    myHeaders.append('Content-Type', 'application/json');
    myHeaders.append('Accept', 'application/json');
    const response = await fetch(`${API_NOAR_BASE}/blog/posts${search}`, {
      method: 'get',
      headers: myHeaders,
      mode: 'cors',
    });

    return await response.json();
  } catch (error) {
    return null;
  }
}

export async function getBlogPostData(post_id) {
  try {
    const myHeaders = new Headers();
    myHeaders.append('Content-Type', 'application/json');
    myHeaders.append('Accept', 'application/json');
    const response = await fetch(`${API_NOAR_BASE}/blog/posts/${post_id}`, {
      method: 'get',
      headers: myHeaders,
      mode: 'cors',
    });

    return await response.json();
  } catch (error) {
    return null;
  }
}

export async function getPersonalActivePlan() {
  const jwt = sessionManager.getJwt();
  if (jwt) {
    try {
      const myHeaders = new Headers();
      myHeaders.append('Content-Type', 'application/json');
      myHeaders.append('Accept', 'application/json');
      myHeaders.append('Authorization', `Bearer ${jwt}`);

      const response = await fetch(`${API_NOAR_BASE}/plans/personal?only_activate=true`, {
        method: 'get',
        headers: myHeaders,
        mode: 'cors',
      });
      sessionManager.checkStatusResponse(response);
      return await response.json();
    } catch (error) {
      console.error('Error in getPersonalPlan', error);
      return null;
    }
  } else {
    throw new SessionExpired();
  }
}

export async function getBusinessActiveAdminPlan() {
  const jwt = sessionManager.getJwt();
  if (jwt) {
    try {
      const myHeaders = new Headers();
      myHeaders.append('Content-Type', 'application/json');
      myHeaders.append('Accept', 'application/json');
      myHeaders.append('Authorization', `Bearer ${jwt}`);

      const response = await fetch(`${API_NOAR_BASE}/plans/business?only_activate=true`, {
        method: 'get',
        headers: myHeaders,
        mode: 'cors',
      });
      sessionManager.checkStatusResponse(response);
      return await response.json();
    } catch (error) {
      console.error('Error in getBusinessActiveAdminPlan', error);
      return null;
    }
  } else {
    throw new SessionExpired();
  }
}

export async function getBusinessSessions(
  page_number = 0,
  page_size = SESSION_DEFAULT_SIZE,
  sort_by,
  order_by,
  search_content,
) {
  const jwt = sessionManager.getJwt();

  if (jwt) {
    try {
      const myHeaders = new Headers();
      myHeaders.append('Content-Type', 'application/json');
      myHeaders.append('Accept', 'application/json');
      myHeaders.append('Authorization', `Bearer ${jwt}`);

      search_content = search_content ? decodeURIComponent(search_content) : undefined;

      const queryParams = { page_number, page_size, sort_by, order_by, search_content };
      Object.keys(queryParams).forEach(key => queryParams[key] === undefined && delete queryParams[key]);

      const response = await fetch(`${API_NOAR_BASE}/clients/business/sessions?${new URLSearchParams(queryParams)}`, {
        method: 'get',
        headers: myHeaders,
        mode: 'cors',
      });
      sessionManager.checkStatusResponse(response);

      return await response.json();
    } catch (error) {
      console.error('Error in get Sessions', error);
      return null;
    }
  } else {
    throw new SessionExpired();
  }
}

export async function getCollaborators(
  page_number = 0,
  page_size = SESSION_DEFAULT_SIZE,
  sort_by,
  order_by,
  search_content,
) {
  const jwt = sessionManager.getJwt();

  if (jwt) {
    try {
      const myHeaders = new Headers();
      myHeaders.append('Content-Type', 'application/json');
      myHeaders.append('Accept', 'application/json');
      myHeaders.append('Authorization', `Bearer ${jwt}`);

      const queryParams = { page_number, page_size, sort_by, order_by, search_content };
      Object.keys(queryParams).forEach(key => queryParams[key] === undefined && delete queryParams[key]);

      const queryString = Object.entries(queryParams)
        .map(([key, value]) => {
          if (key === 'search_content') {
            return `${key}=${value}`;
          }
          return `${key}=${encodeURIComponent(value)}`;
        })
        .join('&');

      const response = await fetch(`${API_NOAR_BASE}/clients/business/collaborators?${queryString}`, {
        method: 'get',
        headers: myHeaders,
        mode: 'cors',
      });
      sessionManager.checkStatusResponse(response);

      if (response.ok) {
        return await response.json();
      } else {
        const responseBody = await response.json();
        throw new B2bActionError(responseBody.message);
      }
    } catch (error) {
      console.error('Error in get Sessions', error);
      return null;
    }
  } else {
    throw new SessionExpired();
  }
}

export async function registerCollab(data) {
  const jwt = sessionManager.getJwt();
  if (jwt) {
    try {
      const myHeaders = new Headers();
      myHeaders.append('Content-Type', 'application/json');
      myHeaders.append('Accept', 'application/json');
      myHeaders.append('Authorization', `Bearer ${jwt}`);

      let url = `${API_NOAR_BASE}/clients/business/register/collaborator`;

      const response = await fetch(url, {
        method: 'post',
        headers: myHeaders,
        mode: 'cors',
        body: JSON.stringify({
          email: data.email,
          password: data.password,
          name: data.name,
          function: data.function,
          permission: 'B2B_COLLAB',
          maximum_hours_available: data.maximum_hours_available,
          maximum_upload_available: data.maximum_upload_available,
          id_image: data.id_image,
          // TODO: REMOVER MOCK
          hardware: {
            ram: 32,
            cpu: 12,
            hd: 500,
            gpu: 50,
            gpuName: 'AUTO',
            network: 'Default',
          }
        }),
      });

      if (response.ok) {
        return await response.json();
      } else {
        const responseBody = await response.json();
        throw new B2bActionError(responseBody.message);
      }
    } catch (error) {
      console.error('Error in registerCollab', error);
      throw error;
    }
  } else {
    throw new SessionExpired();
  }
}

export async function suspendCollab(collabId) {
  const jwt = sessionManager.getJwt();

  if (jwt) {
    const myHeaders = new Headers();
    myHeaders.append('Content-Type', 'application/json');
    myHeaders.append('Accept', 'application/json');
    myHeaders.append('Authorization', `Bearer ${jwt}`);

    let url = `${API_NOAR_BASE}/clients/business/collaborators/${collabId}/suspend`;
    const response = await fetch(url, {
      method: 'post',
      headers: myHeaders,
      mode: 'cors',
    });
    if (response.ok) {
      return;
    } else {
      const responseBody = await response.json();
      throw new B2bActionError(responseBody.message);
    }
  } else {
    throw new SessionExpired();
  }
}

export async function unsuspendCollab(collabId) {
  const jwt = sessionManager.getJwt();

  if (jwt) {
    const myHeaders = new Headers();
    myHeaders.append('Content-Type', 'application/json');
    myHeaders.append('Accept', 'application/json');
    myHeaders.append('Authorization', `Bearer ${jwt}`);

    let url = `${API_NOAR_BASE}/clients/business/collaborators/${collabId}/unsuspend`;
    const response = await fetch(url, {
      method: 'post',
      headers: myHeaders,
      mode: 'cors',
    });
    if (response.ok) {
      return;
    } else {
      const responseBody = await response.json();
      throw new B2bActionError(responseBody.message);
    }
  } else {
    throw new SessionExpired();
  }
}

export async function deleteCollab(collabId) {
  const jwt = sessionManager.getJwt();

  if (jwt) {
    const myHeaders = new Headers();
    myHeaders.append('Content-Type', 'application/json');
    myHeaders.append('Accept', 'application/json');
    myHeaders.append('Authorization', `Bearer ${jwt}`);

    let url = `${API_NOAR_BASE}/clients/business/collaborators/${collabId}`;
    const response = await fetch(url, {
      method: 'delete',
      headers: myHeaders,
      mode: 'cors',
    });
    if (response.ok) {
      return;
    } else {
      const responseBody = await response.json();
      throw new B2bActionError(responseBody.message);
    }
  } else {
    throw new SessionExpired();
  }
}

export async function editCollab(collab) {
  const jwt = sessionManager.getJwt();

  if (jwt) {
    const myHeaders = new Headers();
    myHeaders.append('Content-Type', 'application/json');
    myHeaders.append('Accept', 'application/json');
    myHeaders.append('Authorization', `Bearer ${jwt}`);

    let url = `${API_NOAR_BASE}/clients/business/collaborators/${collab.id_client}`;
    const response = await fetch(url, {
      method: 'put',
      headers: myHeaders,
      mode: 'cors',
      body: JSON.stringify({
        name: collab.name,
        function: collab.function,
        maximum_hours_available: collab.maximum_hours_available,
        maximum_upload_available: collab.maximum_upload_available,
      }),
    });
    if (response.ok) {
      return await response.json();
    } else {
      const responseBody = await response.json();
      throw new B2bActionError(responseBody.message);
    }
  } else {
    throw new SessionExpired();
  }
}

export async function sendNewPasswordChallenge(email, loginChallengeSession, newPassword) {
  const myHeaders = new Headers();
  myHeaders.append('Content-Type', 'application/json');
  myHeaders.append('Accept', 'application/json');

  let url = `${API_NOAR_BASE}/clients/password/challenge/new-password`;

  const response = await fetch(url, {
    method: 'post',
    headers: myHeaders,
    mode: 'cors',
    body: JSON.stringify({
      user_name: email,
      session: loginChallengeSession,
      proposed_password: newPassword,
    }),
  });

  const responseBody = await response.json();

  if (response.ok) {
    return responseBody;
  } else {
    throw new RegisterError(responseBody.message, responseBody.status);
  }
}

export async function getRecurrency() {
  const jwt = sessionManager.getJwt();

  if (jwt) {
    const myHeaders = new Headers();
    myHeaders.append('Content-Type', 'application/json');
    myHeaders.append('Accept', 'application/json');
    myHeaders.append('Authorization', `Bearer ${jwt}`);

    let url = `${API_NOAR_BASE}/payments/recurrency`;
    const response = await fetch(url, {
      method: 'GET',
      headers: myHeaders,
      mode: 'cors',
    });
    if (response.ok) {
      return await response.json();
    } else {
      console.error(await response.json());
      return [];
    }
  } else {
    throw new SessionExpired();
  }
}

export async function getExternalRecurrency() {
  const jwt = sessionManager.getJwt();

  if (jwt) {
    const myHeaders = new Headers();
    myHeaders.append('Content-Type', 'application/json');
    myHeaders.append('Accept', 'application/json');
    myHeaders.append('Authorization', `Bearer ${jwt}`);

    let url = `${API_NOAR_BASE}/payments/external/recurrency`;
    const response = await fetch(url, {
      method: 'PUT',
      headers: myHeaders,
      mode: 'cors',
    });
    if (response.ok) {
      return await response.json();
    } else {
      console.error(await response.json());
      return [];
    }
  } else {
    throw new SessionExpired();
  }
}

export async function cancelRecurrency(recurrencyId) {
  const jwt = sessionManager.getJwt();

  if (jwt) {
    const myHeaders = new Headers();
    myHeaders.append('Content-Type', 'application/json');
    myHeaders.append('Accept', 'application/json');
    myHeaders.append('Authorization', `Bearer ${jwt}`);

    let url = `${API_NOAR_BASE}/payments/${recurrencyId}/cancel`;
    const response = await fetch(url, {
      method: 'PUT',
      headers: myHeaders,
      mode: 'cors',
    });
    return response;
  } else {
    throw new SessionExpired();
  }
}

export async function getCardBin(card) {
  const jwt = sessionManager.getJwt();

  if (jwt) {
    try {
      const myHeaders = new Headers();
      myHeaders.append('Accept', 'application/json');
      myHeaders.append('Authorization', `Bearer ${jwt}`);

      const response = await fetch(`${API_NOAR_BASE}/payments/bin/${card}`, {
        method: 'GET',
        headers: myHeaders,
        mode: 'cors',
      });
      return await response?.json();
    } catch (error) {
      console.error('Error on get Bin Card', error);
      return null;
    }
  } else {
    throw new SessionExpired();
  }
}

export async function getB2BConsumptionData(initial_date, final_date) {
  const jwt = sessionManager.getJwt();

  if (jwt) {
    try {
      const myHeaders = new Headers();
      myHeaders.append('Accept', 'application/json');
      myHeaders.append('Authorization', `Bearer ${jwt}`);

      const onlyDateInitial = initial_date.toISOString().split('T')[0];
      const onlyDateFinal = final_date.toISOString().split('T')[0];

      const response = await fetch(
        `${API_NOAR_BASE}/clients/business/company/consumption-data?initial_date=${onlyDateInitial}&final_date=${onlyDateFinal}`,
        {
          method: 'GET',
          headers: myHeaders,
          mode: 'cors',
        },
      );
      return await response?.json();
    } catch (error) {
      console.error('Error on get B2B Consumption Data', error);
      return null;
    }
  } else {
    throw new SessionExpired();
  }
}

export async function getB2BConsumptionDataByCollab(collabId, initial_date, final_date) {
  const jwt = sessionManager.getJwt();

  if (jwt) {
    try {
      const myHeaders = new Headers();
      myHeaders.append('Accept', 'application/json');
      myHeaders.append('Authorization', `Bearer ${jwt}`);

      const onlyDateInitial = initial_date.toISOString().split('T')[0];
      const onlyDateFinal = final_date.toISOString().split('T')[0];

      const response = await fetch(
        `${API_NOAR_BASE}/clients/business/collaborators/${collabId}/consumption-data?initial_date=${onlyDateInitial}&final_date=${onlyDateFinal}`,
        {
          method: 'GET',
          headers: myHeaders,
          mode: 'cors',
        },
      );
      return await response?.json();
    } catch (error) {
      console.error('Error on get B2B Consumption Data', error);
      return null;
    }
  } else {
    throw new SessionExpired();
  }
}

export async function getReferralVoucherInfoByOwner(initial_date, final_date) {
  const jwt = sessionManager.getJwt();

  if (jwt) {
    try {
      const myHeaders = new Headers();
      myHeaders.append('Accept', 'application/json');
      myHeaders.append('Authorization', `Bearer ${jwt}`);

      const onlyDateInitial = initial_date.toISOString().split('T')[0];
      const onlyDateFinal = final_date.toISOString().split('T')[0];

      const response = await fetch(
        `${API_NOAR_BASE}/voucher/owner?initial_date=${onlyDateInitial}&final_date=${onlyDateFinal}`,
        {
          method: 'GET',
          headers: myHeaders,
          mode: 'cors',
        },
      );
      if (!response.ok) {
        return null;
      }
      return await response?.json();
    } catch (error) {
      return null;
    }
  } else {
    throw new SessionExpired();
  }
}

export async function processPayment(formData, selectedProductList, recurrent, discountVoucher) {
  const jwt = sessionManager.getJwt();
  if (jwt) {
    const myHeaders = new Headers();
    myHeaders.append('Content-Type', 'application/json');
    myHeaders.append('Accept', 'application/json');
    myHeaders.append('Authorization', `Bearer ${jwt}`);
    const response = await fetch(`${API_NOAR_BASE}/payments/external`, {
      method: 'post',
      headers: myHeaders,
      mode: 'cors',
      body: JSON.stringify({
        order_info: {
          order_items: [...selectedProductList],
          recurrent: recurrent,
          discount_code: discountVoucher,
        },
        payment_info: formData,
      }),
    });
    if (!response.ok) {
      if (response.status === 403)
        throw new PurchaseBlockedFor24Hours();
      console.error('Error in processPayment', response);
    }
    return response;
  } else {
    throw new SessionExpired();
  }
}

export async function verifyPurchaseAvailability() {
  const jwt = sessionManager.getJwt();
  if (jwt) {
    const myHeaders = new Headers();
    myHeaders.append('Content-Type', 'application/json');
    myHeaders.append('Accept', 'application/json');
    myHeaders.append('Authorization', `Bearer ${jwt}`);
    const response = await fetch(`${API_NOAR_BASE}/payments/availability`, {
      method: 'get',
      headers: myHeaders,
      mode: 'cors',
    });
    if (!response.ok) {
      if (response.status === 403)
        throw new PurchaseBlockedFor24Hours();
      console.error('Error in ', response);
    }
    return response;
  } else {
    throw new SessionExpired();
  }
}

export async function getPlansFormResult(body) {
  const myHeaders = new Headers();
  myHeaders.append('Content-Type', 'application/json');
  myHeaders.append('Accept', 'application/json');

  let url = `${API_NOAR_BASE}/form/plan`;

  const response = await fetch(url, {
    method: 'post',
    headers: myHeaders,
    mode: 'cors',
    body: JSON.stringify(body),
  });

  const responseBody = await response.json();

  if (response.ok) {
    return responseBody;
  } else {
    throw new PlansFormError(responseBody.message, responseBody.status);
  }
}

export async function getVoucher(voucher) {
  const jwt = sessionManager.getJwt();
  if (jwt) {
    const myHeaders = new Headers();
    myHeaders.append('Accept', 'application/json');
    myHeaders.append('Authorization', `Bearer ${jwt}`);
    const response = await fetch(`${API_NOAR_BASE}/coupons/${voucher}`, {
      method: 'get',
      headers: myHeaders,
      mode: 'cors',
    });
    const responseBody = await response.json();
    if (!response.ok) {
      console.error('Error in getVoucher', responseBody?.message);
    }
    return responseBody;
  } else {
    throw new SessionExpired();
  }
}

export async function sendRecurrencyCardChangeCall(tokenObject) {
  const jwt = sessionManager.getJwt();
  if (jwt) {
    const myHeaders = new Headers();
    myHeaders.append('Content-Type', 'application/json');
    myHeaders.append('Accept', 'application/json');
    myHeaders.append('Authorization', `Bearer ${jwt}`);
    const response = await fetch(`${API_NOAR_BASE}/payments/external/recurrency/payment_method`, {
      method: 'PATCH',
      headers: myHeaders,
      body: JSON.stringify(tokenObject),
    });
    if (!response.ok) {
      console.error('Error in sendRecurrencyCardChangeCall', response);
    }
    return response;
  } else {
    throw new SessionExpired();
  }
}

export async function getPersonalImagemDaysRemaining() {
  const jwt = sessionManager.getJwt();
  if (jwt) {
    const myHeaders = new Headers();
    myHeaders.append('Accept', 'application/json');
    myHeaders.append('Authorization', `Bearer ${jwt}`);
    const response = await fetch(`${API_NOAR_BASE}/plans/personal/image-days`, {
      method: 'get',
      headers: myHeaders,
      mode: 'cors',
    });
    const responseBody = await response.json();
    if (!response.ok) {
      console.error('Error in getPersonalImagemDaysRemaining', responseBody?.message);
    }
    return responseBody;
  }
  else {
    throw new SessionExpired();
  }
}

export async function getLastPersonalPlanInfo() {
  const jwt = sessionManager.getJwt();
  if (jwt) {
    const myHeaders = new Headers();
    myHeaders.append('Accept', 'application/json');
    myHeaders.append('Authorization', `Bearer ${jwt}`);
    const response = await fetch(`${API_NOAR_BASE}/payments/plan`, {
      method: 'get',
      headers: myHeaders,
      mode: 'cors',
    });
    const responseBody = await response.json();
    if (!response.ok) {
      console.error('Error in getLastPersonalPlanInfo', responseBody?.message);
    }
    return responseBody;
  }
  else {
    throw new SessionExpired();
  }
}

export async function getGoldImages(search_content) {
  const jwt = sessionManager.getJwt();
  if (jwt) {
    const myHeaders = new Headers();
    myHeaders.append('Accept', 'application/json');
    myHeaders.append('Authorization', `Bearer ${jwt}`);

    const queryParams = { search_content };
    Object.keys(queryParams).forEach(key => queryParams[key] === undefined && delete queryParams[key]);

    const queryString = Object.entries(queryParams)
      .map(([key, value]) => {
        if (key === 'search_content') {
          return `${key}=${value}`;
        }
        return `${key}=${encodeURIComponent(value)}`;
      })
      .join('&');

    const response = await fetch(`${API_NOAR_BASE}/images/enterprise?${queryString}`, {
      method: 'get',
      headers: myHeaders,
      mode: 'cors',
    });
    const responseBody = await response.json();
    if (!response.ok) {
      console.error('Error in getGoldImages', responseBody?.message);
    }
    return responseBody;
  }
  else {
    throw new SessionExpired();
  }
}

export async function deleteGoldImage(imageId, hostId) {
  console.log('deleteGoldImage', imageId, hostId)
  const jwt = sessionManager.getJwt();
  if (jwt) {
    const myHeaders = new Headers();
    myHeaders.append('Accept', 'application/json');
    myHeaders.append('Authorization', `Bearer ${jwt}`);
    const response = await fetch(`${API_NOAR_BASE}/images/enterprise/${imageId}?host_id=${hostId}`, {
      method: 'delete',
      headers: myHeaders,
      mode: 'cors',
    });
    return response;
  }
  else {
    throw new SessionExpired();
  }
}

export async function editGoldImage(imageId, image) {
  const jwt = sessionManager.getJwt();
  if (jwt) {
    const myHeaders = new Headers();
    myHeaders.append('Content-Type', 'application/json');
    myHeaders.append('Accept', 'application/json');
    myHeaders.append('Authorization', `Bearer ${jwt}`);
    const response = await fetch(`${API_NOAR_BASE}/images/enterprise/${imageId}`, {
      method: 'put',
      headers: myHeaders,
      body: JSON.stringify(image),
      mode: 'cors',
    });
    return response;
  }
  else {
    throw new SessionExpired();
  }
}

export async function registerGoldImage(image) {
  const jwt = sessionManager.getJwt();
  if (jwt) {
    const myHeaders = new Headers();
    myHeaders.append('Content-Type', 'application/json');
    myHeaders.append('Accept', 'application/json');
    myHeaders.append('Authorization', `Bearer ${jwt}`);
    const response = await fetch(`${API_NOAR_BASE}/images/enterprise`, {
      method: 'post',
      headers: myHeaders,
      body: JSON.stringify(image),
      mode: 'cors',
    });
    return response;
  }
  else {
    throw new SessionExpired();
  }
}

export async function listAllHosts() {
  const jwt = sessionManager.getJwt();
  if (jwt) {
    const myHeaders = new Headers();
    myHeaders.append('Accept', 'application/json');
    myHeaders.append('Authorization', `Bearer ${jwt}`);
    const response = await fetch(`${API_NOAR_BASE}/instances/hosts?only_available=false`, {
      method: 'get',
      headers: myHeaders,
      mode: 'cors',
    });
    const responseBody = await response.json();
    if (response.ok) {
      return responseBody;
    } else {
      throw new CreateInstanceError(responseBody.message);
    }
  }
  else {
    throw new SessionExpired();
  }
}