import classic from './classic.svg';
import unit from './unit.svg';

export const plans = [
  {
    name: 'Personal',
    price: {
      text: 'A partir de',
      unity: 'mês',
      value: 65.76,
    },
    payment: 'Mensal recorrente<br/><e>(crédito)</e>',
    resolution: "HD no app moonlight e até 4k utilizando o parsec",
    time: 'Gerenciável com horas adcionadas mensalmente a sua conta',
    use_type: 'Gerenciável através de horas',
    storage: true,
    pre_installed_programs: "Não, você monta seu desktop"
  }, {

    descriptions: ['Assinatura mensal \ncom sessões ilimitadas', 'Limite de tempo por sessão'],
    logo: { src: unit, width: 108, height: 52 },
    name: 'Unit',
    payment: 'Mensal avulso ou recorrencia',
    price: {
      text: 'A partir de',
      unity: 'mês',
      value: 89.99,
    },
    spot: true,
    storage: false,
    time: 'Até 5 horas de sessão, podendo acessar quantas vezes quiser.',
    defaultSettings: true,
    resolution: "8k",
    licenses: false,
    use_type: 'Ilimitado',
    pre_installed_programs: "Jogos diversos e softwares de edição e renderização, mas com liberdade para instalar o que quiser."
  },
  {
    descriptions: [
      'Plano com pacotes \nde horas, ou horas avulsas',
      'Sem armazenamento incluso',
      'Gerencie o tempo de sua sessão',
      'Sem spot',
    ],
    logo: { src: classic, width: 152, height: 52 },
    name: 'Horas avulsas',
    payment: 'Avulso<br/><e>(crédito, débito e pix)</e>',
    price: {
      text: 'A partir de',
      unity: 'hora',
      value: 3.48,
    },
    spot: false,
    storage: false,
    time: 'Gerenciável através de horas',
    resolution: "HD no app moonlight e até 4k utilizando o parsec",
    defaultSettings: true,
    licenses: false,
    use_type: 'Gerenciável através de horas',
    pre_installed_programs: "Jogos diversos e softwares de edição e renderização, mas com liberdade para instalar o que quiser"
  },
];

export default plans;
