import styled from 'styled-components';

import { mqmax, desktopMinSize } from '@Styles/mixins';

export const Div = styled.div`
  display: flex;

  ${mqmax(desktopMinSize - 1)} {
    flex-direction: column;
  }
`;
