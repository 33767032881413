import React, { useState, useRef, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import Wrapper from './Wrapper';
import Overlay from './Overlay';
import PaymentCard from './PaymentCard';
import PaymentPix from './PaymentPix';
import PersonalData from './PersonalData';
import AdressData from './AdressData';
import { PaymentAlert } from './PaymentAlert';
import {
  UNENROLLED,
  PAID,
  DENIED,
  PENDING,
  ERROR,
  EXPIRED,
  SELECTED_PLAN_KEY,
  REDIRECT_AFTER_LOGIN,
  messageDisconnect,
} from '@Constants';
import { SessionExpired, PurchaseBlockedFor24Hours } from '@Errors';
import { openConfirmationLightbox } from '@Store/ducks/global/actions';
import { setSelectedPlan } from '@Store/ducks/computer/actions';
import { Loading } from '../../Loading';
import { processPayment } from '../../../services/noar-api';
import { generateCardToken } from '../../../services/pagarme-api';
import PaymentStatusComponent from '../ShopPageCard/MpPaymentStatusBrick';

export const Payment = ({ close, selectedProductList, productType, accountPersonalInfo }) => {
  const [viewControl, handleViewControl] = useState(0);
  const [paymentOrder, handlePaymentOrder] = useState(null);
  const [paymentId , setPaymentId] = useState(null);
  const [personalInfo, handlePersonalInfo] = useState(null);
  const [adressInfo, handleAdressInfo] = useState({ keepAdressOption: true, changedKeepAdressOption: false });
  const [cardInfo, handleCardInfo] = useState(null);
  const [confirmPaymentReturn, handleConfirmPaymentReturn] = useState(null);
  const [returnMessage, handleReturnMessage] = useState({});
  const [loading, handleLoading] = useState(false);
  const paymentAlertRef = useRef();
  const dispatch = useDispatch();
  const history = useHistory();
  const handleBuyProducts = async () => {
    try {
      handleLoading(true);
      const cardTokenResponse = await generateCardToken(
        cardInfo.card.number,
        cardInfo.card.holder,
        {
          expiration_month: cardInfo.card.expiration_month,
          expiration_year: cardInfo.card.expiration_year.replace('20', ''),
        },
        cardInfo.card.security_code,
        cardInfo.card.brand
      );
      const cardToken = cardTokenResponse?.id;

      const formattedBirthDate = personalInfo.birthDate.split('-').reverse().join('-');

      const formData = {
        token: cardToken,
        payer: {
          address: {
            street: adressInfo.billing.endereco,
            number: adressInfo.billing.numero,
            complement: adressInfo.billing.complemento,
            neighborhood: adressInfo.billing.bairro,
            zip_code: adressInfo.billing.cep,
            state: adressInfo.billing.estado,
            country: 'BR',
            city: adressInfo.billing.cidade,
          },
          identification: {
            number: personalInfo.cpfCnpj,
            type: personalInfo.cpfCnpj.length === 11 ? 'cpf' : 'cnpj',
          },
          birth_date: formattedBirthDate
        },
      };

      const paymentResponse = await processPayment(formData, selectedProductList, true);
      handleLoading(false);
      if (!paymentResponse.ok) {
        var respondeBody = await paymentResponse.json();
        var respondeBodyMessageAsJson = JSON.parse(respondeBody?.message);
        const message = respondeBodyMessageAsJson?.message && respondeBodyMessageAsJson?.message != ""
          ? `Sua compra foi recusada: ${respondeBodyMessageAsJson?.message}. Por favor, revise ou troque as informações de pagamento. Caso necessário, entre em contato com o suporte.`
          : `Sua compra foi recusada: Transação recusada por excesso de retentativas. Por favor, revise ou troque as informações de pagamento. Caso necessário, entre em contato com o suporte.`;
        handleConfirmPaymentReturn({ status: ERROR, message: message });
      }
      else {
        handleConfirmPaymentReturn({ status: PAID, message: "Seu pagamento foi aprovado com sucesso!" });
      }
    } catch (error) {
      handleLoading(false);
      if (error instanceof SessionExpired) {
        dispatch(openConfirmationLightbox(messageDisconnect));
        return;
      } else if (error instanceof PurchaseBlockedFor24Hours) {
        dispatch(openConfirmationLightbox(
          () => {
            document.location.reload()
          },
          <>Você excedeu o limite de tentativas recusadas. Você pode tentar novamente após 24 horas. Caso necessário, entre em contato com o suporte.</>,
          'Entendi',
          null,
          null,
          null,
          false,
        ));
      }
      console.error(error);
    }
  }

  const closePaymentAlert = () => {
    paymentAlertRef.current.close();
    if (confirmPaymentReturn?.status === PAID) {
      handleCloseApprovedPayment();
    }
  };

  const handleOpenPaymentStatusMessage = () => {
    if (paymentAlertRef.current?.state?.isOpen) {
      paymentAlertRef.current.closeModal();
    }
    paymentAlertRef.current.showModal();
  };

  const showPaymentAlert = () => {
    if (confirmPaymentReturn?.status === PAID) {
      handleReturnMessage({
        title: 'Pagamento Aprovado',
        message: `Seu pedido foi gerado com sucesso!`,
        variant: 'denied',
      });
      handleOpenPaymentStatusMessage();
    } else if (confirmPaymentReturn?.status === DENIED) {
      handleReturnMessage({
        title: 'Pagamento Negado',
        message: confirmPaymentReturn?.message,
        variant: 'denied',
      });
      handleOpenPaymentStatusMessage();
    } else if (confirmPaymentReturn?.status === UNENROLLED) {
      handleReturnMessage({
        title: 'Cartão não suportado',
        message: `Seu cartão não é suportado pelo sistema<br />
        de pagamento, ou está preenchido incorretamente.<br /> 
        Por favor, revise os dados ou tente novamente<br />
        utilizando outro cartão.`,
        variant: 'denied',
      });
      handleOpenPaymentStatusMessage();
    } else if (confirmPaymentReturn?.status === PENDING) {
      handleReturnMessage({
        title: 'Pagamento em análise',
        message: confirmPaymentReturn?.message,
        variant: 'pending',
      });
      handleOpenPaymentStatusMessage();
    } else if (confirmPaymentReturn?.status === ERROR) {
      handleReturnMessage({
        title: 'Compra não realizada',
        message: confirmPaymentReturn?.message,
        variant: 'denied',
      });
      handleOpenPaymentStatusMessage();
    } else if (confirmPaymentReturn?.status === EXPIRED) {
      handleReturnMessage({
        title: 'Pagamento expirado',
        message: `O tempo do seu pedido expirou.`,
        variant: 'denied',
      });
      handleOpenPaymentStatusMessage();
    }
  };

  const closeAll = () => {
    try {
      if (loading && viewControl === 0) return;
      handleViewControl(0);
      close();
    } catch (error) {
      console.warn('Already closed. Error message: ', error.toString());
    }
  };

  const handleCloseApprovedPayment = () => {
    closeAll();
    localStorage.removeItem(SELECTED_PLAN_KEY);
    dispatch(setSelectedPlan(null));
    history.push(REDIRECT_AFTER_LOGIN);
  };

  useEffect(() => {
    if (cardInfo) {
      handleBuyProducts();
    }
  }, [cardInfo]);

  useEffect(() => {
    showPaymentAlert();
  }, [confirmPaymentReturn]);

  useEffect(() => {
    handleViewControl(1);
  }, []);

  return (
    <>
      <Wrapper>
        <Overlay onClick={closeAll} />
        {loading ? (
          <Loading whichLoading={'generic'} />
        ) : (
          <>
            {viewControl === 1 ? (
              <PersonalData
                close={closeAll}
                handleViewControl={handleViewControl}
                productType={productType}
                paymentOrder={paymentOrder}
                personalInfo={personalInfo}
                handlePersonalInfo={handlePersonalInfo}
                handleConfirmPaymentReturn={handleConfirmPaymentReturn}
                selectedProductList={selectedProductList}
                setPaymentId={setPaymentId}
              />
            ) : (
              []
            )}
            {viewControl === 2 ? (
              <AdressData
                close={closeAll}
                handleViewControl={handleViewControl}
                adressInfo={adressInfo}
                handleAdressInfo={handleAdressInfo}
                handleConfirmPaymentReturn={handleConfirmPaymentReturn}
              />
            ) : (
              []
            )}
            {viewControl === 3 ? (
              <PaymentStatusComponent paymentId={paymentId} productLink="/shop/unit" />
            ) : (
              []
            )}

            {viewControl === 4 ? (
              <PaymentCard
                close={closeAll}
                handleViewControl={handleViewControl}
                cardInfo={cardInfo}
                handleCardInfo={handleCardInfo}
                handleLoading={handleLoading}
              />
            ) : (
              []
            )}
          </>
        )}
      </Wrapper>
      <PaymentAlert paymentAlertRef={paymentAlertRef} close={closePaymentAlert} {...returnMessage} />
    </>
  );
};

export default Payment;
