export const faqs = [
  {
    id: 'plano-mensal',
    question: 'Minha máquina está travando ou com lag.',
    answer:`
    • Para casos de travamentos verifique se as configurações estão corretas no moonlight para o seu plano e dispositivo.<br><br>
    • Vá em configurações dentro do moonlight, reduza o bitrate para o recomendável (recomendamos abaixo de 10mbps para não haver travamentos). Caso o seu computador não suporte, desative o V-Sync e selecione o encode de acordo com o seu dispositivo (recomendamos testar em h265, h264 e caso não funcione nenhuma das opções, software).<br><br>
    • Escolha a opção 60 FPS. Caso ainda mantenha travamentos, recomendamos baixar para 30 FPS, pois isso significa que seu dispositivo não suporta mais.
    `
  },
  {
    id: 'qualquer-aplicativo-ou-jogo.',
    question: 'Se eu contratar o plano Personal (monte o seu computador) com 10 horas, terei 10 horas por dia?',
    answer:`
    • Não, o plano NOAR-Personal funciona como o de horas avulsas, você escolhe a quantidade de armazenamento que deseja ter na sua máquina, quantas horas mensais deseja e, caso utilize todas as suas horas, terá que adicionar mais tempo para usar antes da renovação. O diferencial é que as horas adquiridas no plano Personal possuem um preço mais baixo.
    `
  },
  {
    id: 'horas-contabilizadas',
    question: 'Eu só posso jogar uma sessão por dia no plano mensal (Unit)?',
    answer: `
    • Não, você pode jogar quantas sessões quiser, mas o plano Unit oferece 40 horas com alta prioridade no plano. Após usar essas 40 horas, terá a quantidade de máquinas reduzidas, o que aumenta a chance de pegar filas.
    `
  },
  {
    id: 'formas-pagamento',
    question: 'Consigo usar no IOS (Iphone e Ipad)?',
    answer: `
    • Sim, para para se conectar via moonlight pelo IOS você precisa copiar o IP no site e inserir no moonlight desta forma [::ffff:IP] EX: [::ffff:169.257.0.16].
    `
  },
  {
    id: 'teclado-mobile',
    question: 'Como posso acessar o teclado virtual no celular/tablet?',
    answer:`
    • Para acessar o teclado no celular/tablet, basta clicar com tres dedos simultaneamente na tela.`
  },
  {
    id: 'dispositivos-rodam-noar',
    question: 'Qual é a resolução dos computadores da NOAR.',
    answer:`
    • A resolução 8k está disponível em todos os planos. Nos planos Personal e Classic, caso não esteja nativamente disponível para alteração via painel de controle da Nvidia, é necessário usar o Parsec e ativar a opção de monitor virtual da própria ferramenta. Em caso de dúvidas, entre em contato com o suporte pelo email contato@noarcloud.com ou pela nossa comunidade do discord.
    `
  },
  {
    id: 'salvar-dados-ao-desligar-maquina',
    question: 'Como funciona o plano Enterprise?',
    answer:`
    • Para saber mais detalhes sobre o plano Enterprise, entre em contato com nossa equipe de vendas pelo email contato@noarcloud.com.
    `
  },
  {
    id: 'data-validade-horas',
    question: 'Posso jogar Fivem e Valorant?',
    answer: `
    • O FiveM possui um sistema anti-cheat que não permite seu funcionamento em máquinas virtuais. Você consegue jogar, mas corre riscos de ter a conta banida. O Valorant não roda em máquinas virtuais, assim como em outros jogos. Antes de comprar, verifique se seu jogo é compatível com máquinas virtuais.
    `
  },
  {
    id: 'esqueci-computar-ligado',
    question: 'É possível rodar qualquer aplicativo/jogo?',
    answer:`
    • Com a NOAR é possível rodar qualquer aplicativo ou jogo compatível com os nossos sistemas. Verifique a compatibilidade.
    `
  },
  {
    id: 'fila-espera',
    question: 'Quais são as formas de pagamento disponíveis?',
    answer:`
    • Para o plano NOAR Personal, só é possível fazer a assinatura via cartão de crédito.<br><br>
    • Nos planos Unit e Classic você pode pagar via PIX ou cartão de crédito.<br><br>
    • Para o plano Enterprise dependerá do tipo de contrato.
    `
  },
  {
    id: 'horas-nao-constam',
    question: 'Em quais dispositivos eu consigo utilizar a NOAR?',
    answer:`
    • Utilizando o aplicativo Moonlight como forma de conexão do seu dispositivo ao computador NOAR, você consegue se conectar a partir de computadores Windows, Linux e MacOs, smartphones e tablets Android, Tv Box’s, Raspberry, entre outros.
    `
  },
  {
    id: 'restricao-de-conteudo',
    question: 'Meus dados serão salvos quando desligar a máquina?',
    answer: `
    • Para Clientes NOAR Personal (no plano monte o seu computador) sim, sempre que desligar é feito um backup da sua sessão, salvando todos os seus arquivos. No plano Unit e Classic (com horas avulsas) não, sempre que finalizar a sessão, seus dados serão deletados imediatamente.
    `
  },
  {
    id: 'resolucao-do-computador',
    question: 'As horas compradas possuem validade?',
    answer:`
    • Sim, elas são válidas por 3 meses, caso adicione mais horas, a validade será estendida para mais 3 meses.
    `
  },
  {
    id: 'meus-arquivos-ficam-salvos-durante-quanto-tempo.',
    question: 'Como minhas horas são contabilizadas?',  
   answer:`
   • Assim que você iniciar o computador suas horas já começam a contar, mesmo que você não se conecte ou utilize o computador.
   `
  },
  {
    id: 'se-eu-atrasar-o-pagamento-meus-arquivos-serao-excluidos',
    question: 'Posso instalar programas e jogos piratas?',  
   answer:`
   • A NOAR é contra o uso de programas piratas.
   `
  },
  {
    id: 'eu-posso-aumentar-ou-reduzir-a-quantidade-de-armazenamento-do-personal',
    question: 'Esqueci o computador ligado e minhas horas sumiram.',  
   answer:`
   • O uso das horas é de responsabilidade do usuário. Caso esqueça o computador ligado, as horas não serão devolvidas.
   `
  },
  {
    id: '1',
    question: 'Existe restrição de conteúdo para menores de idade?',  
   answer:`
   • Não, o uso da máquina é livre. Nós recomendamos o uso acompanhado para menores de idade.
   `
  },
];

export default faqs;
