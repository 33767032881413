import React from 'react';
import { StatusScreen, initMercadoPago } from '@mercadopago/sdk-react';
import { NOAR_SITE, MP_PUBLIC_KEY } from '@Constants';
import { Container } from './styled';
function PaymentStatusComponent({ paymentId, productLink }) {

  initMercadoPago(MP_PUBLIC_KEY);

  const initialization = {
    paymentId: paymentId,
  };
  const customization = {
    backUrls: {
      return: `${NOAR_SITE}${productLink}`,
    },
    visual: {
      showExternalReference: true,
      texts: {
        ctaReturnLabel: "Voltar para a loja",
      }
    },
  };
  const onReady = () => {
    // Callback called when Brick is ready
  };
  const onError = error => {
    console.log(error);
  };

  return (
    <Container>
      <StatusScreen initialization={initialization} customization={customization} onReady={onReady} onError={onError} />
    </Container>
  );
}

export default PaymentStatusComponent;
