import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
  Container,
  FilterWrapper,
  CreateUserButton,
  HeadersWrapper,
  CreateUserButtonWrapper,
  CollaboratorInfoActionButtonWrapper,
  CollaboratorInfoActionButton,
} from './styled';
import { getCollaborators } from '@Services/noar-api';
import Combobox from 'react-widgets/Combobox';
import 'react-widgets/styles.css';
import { BsSearch } from 'react-icons/bs';
import { DeleteOutline, EditOutlined, InfoOutlined, LockOutlined, LockOpenOutlined } from '@material-ui/icons';

import { useQuery, useQueryClient } from '@tanstack/react-query';
import { deleteCollab, editCollab, getGoldImages, registerCollab, suspendCollab, unsuspendCollab } from '../../../services/noar-api';
import { PopupFormModal } from './PopupModal';
import Overlay from './PopupModal/Overlay';
import { desktopMinSize } from '../../../styles/mixins';
import MobileWrapper from './Mobile/Wrapper';
import DesktopWrapper from './Desktop';
import { Title } from '../styles';
import PopupInfoModal from './PopupInfoModal';

const useStyles = makeStyles({
  root: {
    color: '#fff',
  },
});

const CollabSection = ({ admPlan }) => {
  const [collaboratorsPageInfo, setCollaboratorsPageInfo] = useState({});
  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(0);
  const [retrieveCollaboratorsNames, setRetrieveCollboratorsNames] = useState([]);
  const [itemsPerPage, setItemsPerPage] = useState(5);
  const classes = useStyles();
  const [tooltipOpen, setTooltipOpen] = useState(false);
  const [showFormPopupModalContent, setShowFormPopupModal] = useState(false);
  const [showInfoPopupModalContent, setShowInfoPopupModal] = useState(false);
  const [actionFunction, setActionFunction] = useState(null);
  const [actionTitle, setActionTitle] = useState(null);
  const [action, setAction] = useState(null);
  const [isMobile] = useState(window.innerWidth < desktopMinSize);
  const queryClient = useQueryClient();

  const { data: collaboratorsData } = useQuery({
    queryKey: ['collaboratorsData'],
    queryFn: () => getCollaborators(currentPage, itemsPerPage, undefined, undefined, retrieveCollaboratorsNames),
    refetchOnWindowFocus: false,
  });

  const { data: goldImages } = useQuery({
    queryKey: ['goldImages'],
    queryFn: () => getGoldImages(),
    refetchOnWindowFocus: false,
  });

  const actions = collab => {
    return {
      edit: {
        ico: <EditOutlined />,
        title: 'Editar',
      },
      delete: {
        ico: <DeleteOutline />,
        title: 'Deletar',
      },
      block: {
        ico: collab.active==='ACTIVE' ? <LockOutlined /> : <LockOpenOutlined />,
        title: collab.active==='ACTIVE' ? 'Suspender' : 'Liberar Acesso',
      },
      info: {
        ico: <InfoOutlined />,
        title: 'Informações',
      },
    };
  };

  const handleTooltipOpen = () => {
    setTooltipOpen(!tooltipOpen);
  };

  async function getData() {
    try {
      setLoading(true);
      const response = await getCollaborators(currentPage, itemsPerPage, undefined, undefined);
      setCollaboratorsPageInfo(response.page_info);
      setLoading(false);
    } catch (error) {
      console.error(error);
      setLoading(false);
      throw error;
    }
  }

  const handlePagination = pageNumber => setCurrentPage(pageNumber);

  function handleUpdateFormPopupModal(collab) {
    handleInfoPopupModal(collab);
    setShowFormPopupModal(!showFormPopupModalContent);
  }

  function handleFormPopupModal() {
    setShowFormPopupModal(!showFormPopupModalContent);
  }

  function handleInfoPopupModal(collab) {
    handleSetActionFunction({ data: collab });
    handleSetActionTitle('Infos do Usuário');
    setAction('info');
    setShowInfoPopupModal(!showInfoPopupModalContent);
  }

  function handleSetActionFunction(action) {
    setActionFunction(action);
  }

  function handleSetActionTitle(text) {
    setActionTitle(text);
  }

  const retrieveCollaboratorsData = collaboratorsData?.data.reduce((acc, data) => {
    acc.push(data.name, data.email);
    return acc;
  }, []);

  const handleChange = event => {
    setItemsPerPage(event.target.value);
  };

  function handleEditCollab(collab) {
    try {
      handleInfoPopupModal(collab);
      handleSetActionFunction({ actionFunction: sendEditCollab, data: collab });
      handleSetActionTitle('Editar Usuário');
      setAction('update');
      handleFormPopupModal(collab);
    } catch (error) {
      console.error(error);
      throw error;
    }
  }

  function handleCreateCollab() {
    try {
      handleSetActionFunction({ actionFunction: sendCreateCollab, data: undefined });
      handleSetActionTitle('Novo Usuário');
      setAction('create');
      handleFormPopupModal();
    } catch (error) {
      console.error(error);
      throw error;
    }
  }

  function handleSuspendCollab(collab) {
    try {
      handleSetActionFunction({ actionFunction: sendSuspendCollab, data: collab });
      handleSetActionTitle(`Tem certeza que deseja suspender ${collab.name}?`);
      setAction('suspend');
      handleFormPopupModal();
    } catch (error) {
      console.error(error);
      throw error;
    }
  }

  function handleUnsuspendCollab(collab) {
    try {
      handleSetActionFunction({ actionFunction: sendUnsuspendCollab, data: collab });
      handleSetActionTitle(`Tem certeza que deseja reativar ${collab.name}?`);
      setAction('activate');
      handleFormPopupModal();
    } catch (error) {
      console.error(error);
      throw error;
    }
  }

  function handleDeleteCollab(collab) {
    try {
      handleSetActionFunction({ actionFunction: sendDeleteCollab, data: collab });
      handleSetActionTitle(
        <>
          <p>Tem certeza que deseja excluir {collab.name}?</p>
          <p>Todas as informações da conta serão apagadas!</p>
        </>,
      );
      setAction('delete');
      handleFormPopupModal();
    } catch (error) {
      console.error(error);
      throw error;
    }
  }

  async function sendDeleteCollab(id) {
    try {
      await deleteCollab(id);
      queryClient.fetchQuery({
        queryKey: ['collaboratorsData'],
      });
    } catch (error) {
      console.error(error);
      throw error;
    }
  }

  async function sendUnsuspendCollab(id) {
    try {
      await unsuspendCollab(id);
      queryClient.fetchQuery({
        queryKey: ['collaboratorsData'],
      });
    } catch (error) {
      console.error(error);
      throw error;
    }
  }

  async function sendSuspendCollab(id) {
    try {
      await suspendCollab(id);
      queryClient.fetchQuery({
        queryKey: ['collaboratorsData'],
      });
    } catch (error) {
      console.error(error);
      throw error;
    }
  }

  async function sendEditCollab(data) {
    try {
      await editCollab(data);
      queryClient.fetchQuery({
        queryKey: ['collaboratorsData'],
      });
    } catch (error) {
      console.error(error);
      throw error;  
    }
  }

  async function sendCreateCollab(data) {
    try {
      await registerCollab(data);
      queryClient.fetchQuery({
        queryKey: ['collaboratorsData'],
      });
    } catch (error) {
      console.error(error);
      throw error;
    }
  }

  useEffect(() => {
    getData();
  }, [currentPage, itemsPerPage]);

  useEffect(() => {
    queryClient.fetchQuery({
      queryKey: ['collaboratorsData'],
    });
  }, [currentPage, itemsPerPage, retrieveCollaboratorsNames]);

  useEffect(() => {
    queryClient.fetchQuery({
      queryKey: ['goldImages'],
    });
  }, []);

  return (
    <Container>
      <Title>Colaboradores</Title>
      <HeadersWrapper>
        <FilterWrapper>
          <Combobox
            value={retrieveCollaboratorsNames}
            onChange={e => setRetrieveCollboratorsNames(e)}
            data={retrieveCollaboratorsData}
            placeholder={'Buscar usuário'}
            selectIcon={<BsSearch size={14} />}
            messages={{
              emptyList: 'Nenhuma atividade detectada',
              emptyFilter: 'Nenhum nome encontrado',
            }}
          />
        </FilterWrapper>
        <CreateUserButtonWrapper>
          <CreateUserButton onClick={handleCreateCollab}>ADICIONAR USUÁRIO</CreateUserButton>
          <CollaboratorInfoActionButtonWrapper>
            <InfoOutlined onClick={handleTooltipOpen} />
            {tooltipOpen ? (
              <>
                <Overlay onClick={handleTooltipOpen} />
                <CollaboratorInfoActionButton>
                  <p>O número de usuários disponíveis para o seu plano é um total de: </p>
                  <p>
                    <strong>{admPlan.amount_collaborators} usuários.</strong>
                  </p>
                </CollaboratorInfoActionButton>
              </>
            ) : (
              []
            )}
          </CollaboratorInfoActionButtonWrapper>
        </CreateUserButtonWrapper>
      </HeadersWrapper>
      {isMobile ? (
        <MobileWrapper
          loading={loading}
          collaboratorsData={collaboratorsData}
          itemsPerPage={itemsPerPage}
          actions={actions}
          handleInfoPopupModal={handleInfoPopupModal}
          handleSuspendCollab={handleSuspendCollab}
          handleUnsuspendCollab={handleUnsuspendCollab}
          handleDeleteCollab={handleDeleteCollab}
        />
      ) : (
        <DesktopWrapper
          loading={loading}
          collaboratorsData={collaboratorsData}
          collaboratorsPageInfo={collaboratorsPageInfo}
          itemsPerPage={itemsPerPage}
          actions={actions}
          handleSuspendCollab={handleSuspendCollab}
          handleUnsuspendCollab={handleUnsuspendCollab}
          handleDeleteCollab={handleDeleteCollab}
          handleInfoPopupModal={handleInfoPopupModal}
          handleChange={handleChange}
          handlePagination={handlePagination}
          classes={classes}
        />
      )}
      {showFormPopupModalContent ? (
        <PopupFormModal
          close={action === 'update' ? handleUpdateFormPopupModal : handleFormPopupModal}
          actionFunction={actionFunction}
          title={actionTitle}
          action={action}
          queryClient={queryClient}
          admPlan={admPlan}
          goldImages={goldImages}
        />
      ) : (
        []
      )}
      {showInfoPopupModalContent ? (
        <PopupInfoModal
          close={handleInfoPopupModal}
          actionFunction={actionFunction}
          admPlan={admPlan}
          handleEditCollab={handleEditCollab}
          actions={actions}
          goldImages={goldImages}
        />
      ) : (
        []
      )}
    </Container>
  );
};

export default CollabSection;
