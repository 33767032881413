import { useEffect, useState } from 'react';
import * as S from './styled';
import { PersonalPlanCardButton } from '../styled';
import { MobileDivider, MobileDividerWrapper } from '../../Classic/styled';
import AssembleDetailPhase from './AssembleDetailPhase';
import StorageAssemblePhase from './AssembleChoicesPhase/StorageAssemblePhase';
import HoursAssemblePhase from './AssembleChoicesPhase/HoursAssemblePhase';
import SubscriptionSummary from './SubscriptionSummary';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import * as sessionManager from '@Utils/session-manager';
import { SessionExpired } from '../../../../../errors';
import { useDispatch } from 'react-redux';
import { openConfirmationLightbox } from '../../../../../store/ducks/global/actions';
import { REDIRECT_TO_LOGIN, messageDisconnect } from '../../../../../constants';
import { cancelRecurrency, getExternalRecurrency, getRecurrency } from '../../../../../services/noar-api';

const AssembleComputerWrapper = ({ handleOrder, selectedProductList, products, isOpen, disableButton, isPublic, phase, setPhase }) => {
  const [storagePrice, setStoragePrice] = useState(31.01);
  const [hoursPrice, setHoursPrice] = useState(31.95);
  const [storageValue, setStorageValue] = useState(100);
  const [hoursValue, sethoursValue] = useState(5);
  const [soldOff, setSoldOff] = useState(false);
  const [recurrency, setRecurrency] = useState();
  const [disable, setDisable] = useState(false);
  const history = useHistory();
  const dispatch = useDispatch();

  function generateStoragePrices() {
    if (!products) return;
    const personalStorage = products.filter(product => product.name === 'PERSONAL STORAGE')[0];
    const quantityProfits = personalStorage?.quantity_profits;
    const prices = {};

    quantityProfits.forEach(quantityProfit => {
      const price = personalStorage.price * quantityProfit.quantity * (1 + quantityProfit.profit_percentage / 100);
      prices[quantityProfit.quantity * 50] = price;
    });

    return prices;
  }

  const storagePrices = generateStoragePrices();

  const phasesInfo = {
    0: {
      buttonText: 'Próximo',
      buttonDisabled: false,
      action: () => handleNextPhase(1),
    },
    1: {
      buttonText: 'Próximo',
      buttonDisabled: false,
      action: () => handleNextPhase(),
    },
    2: {
      buttonText: 'Assinar',
      buttonDisabled: false,
      action: () => handleOrder(generateOrdersProduct()),
    },
  };

  function handlePreviousPhase() {
    setPhase(phase - 1);
  }

  function handleNextPhase() {
    setPhase(phase + 1);
  }

  function generateOrdersProduct() {
    const personalHourId = products.find(product => product.name === 'PERSONAL HOUR').product_id;
    const personalStorageId = products.find(product => product.name === 'PERSONAL STORAGE').product_id;

    return [
      {
        product_id: personalHourId,
        quantity: hoursValue,
      },
      {
        product_id: personalStorageId,
        quantity: storageValue / 50,
      },
    ];
  }

  function getTotalValue() {
    if (phase === 0) return storagePrice.toFixed(2);
    if (phase > 0) return (storagePrice + hoursPrice).toFixed(2);
  }

  function redirectToHoursShop() {
    history.push('/shop/hours');
  }

  function redirectToInitPoint() {
    window.location.href = recurrency.initPoint;
  }

  function getOnClickButtonFunction() {
    return recurrency?.recurrencyStatus === 'ACTIVE'
      ? redirectToHoursShop()
      : recurrency?.recurrencyStatus === 'PENDING' && !recurrency?.initPoint
        ? () => { }
        : recurrency?.recurrencyStatus === 'PENDING' && recurrency?.initPoint
          ? redirectToInitPoint()
          : phasesInfo[phase].action();
  }

  function getButtonText() {
    var text = phasesInfo[phase].buttonText;

    if (recurrency?.recurrencyStatus === 'ACTIVE') {
      text = 'Comprar horas';
    } else if (recurrency?.recurrencyStatus === 'PENDING' && !recurrency?.initPoint) {
      text = 'Aguardando pagamento inicial';
    } else if (recurrency?.recurrencyStatus === 'PENDING' && recurrency?.initPoint) {
      text = 'Continuar último pagamento';
    } else if (soldOff) {
      text = 'Esgotado...';
    }
    return text;
  }

  function onClickRemakePlan() {
    setDisable(true);
    cancelRecurrency(recurrency.externalRecurrencyId)
      .then(() => {
        setRecurrency(undefined);
        setPhase(0);
        setDisable(false);
      })
      .catch(() => {
        setDisable(false);
      });
  }

  useEffect(() => {
    async function handleUpdatePersonalInfo() {
      try {
        await getExternalRecurrency().then(response => {
          if (response.length > 0) {
            const recurrency = response.sort((a, b) => {
              return new Date(b.recurrencyDate) - new Date(a.recurrencyDate);
            })[0];
            setRecurrency(recurrency);
          }
        });
      } catch (error) {
        if (error instanceof SessionExpired) {
          sessionStorage.removeItem('@NOARCLOUD-my-home-page-plan-info-modal');
          const turnOnMachine = false;
          const disconnect = true;
          dispatch(
            openConfirmationLightbox(
              () => {
                history.push(REDIRECT_TO_LOGIN);
              },
              messageDisconnect.message,
              messageDisconnect.confirmBtnText,
              messageDisconnect.refuseBtnText,
              turnOnMachine,
              disconnect,
              false,
            ),
          );
        }
      }
    }
    if (!isPublic) {
      handleUpdatePersonalInfo();
    }
  }, []);

  useEffect(() => {

    //pegar os produtos da lista e atualizar as variaveis de valores
    if(selectedProductList && selectedProductList.length > 0){
      const storageProduct = products.find(product => product.name === 'PERSONAL STORAGE');
      const hoursProduct = products.find(product => product.name === 'PERSONAL HOUR');

      const storageProductAmount = selectedProductList.find(product => product.product_id === storageProduct.product_id)?.quantity;
      const hoursProductAmount = selectedProductList.find(product => product.product_id === hoursProduct.product_id)?.quantity;
      if(storageProduct){
        setStoragePrice(storagePrices[storageProductAmount * 50]);
        setStorageValue(storageProductAmount * 50);
      }
      if(hoursProduct){
        setHoursPrice(hoursProduct.price * hoursProductAmount);
        sethoursValue(hoursProductAmount);
      }
    }

  }, [selectedProductList]);
  return (
    <S.ContentWrapper>
      <S.AssembleWrapper>
        <MobileDividerWrapper>
          <MobileDivider />
        </MobileDividerWrapper>
        <S.Title>Monte seu PC</S.Title>
        <S.PurchasePhaseOneWrapper phase={phase}>
          <AssembleDetailPhase />
          <StorageAssemblePhase
            setStoragePrice={setStoragePrice}
            storagePrice={storagePrice}
            products={products}
            storageValue={storageValue}
            setStorageValue={setStorageValue}
            storagePrices={storagePrices}
          />
        </S.PurchasePhaseOneWrapper>
        <S.PurchasePhaseTwoWrapper phase={phase}>
          <HoursAssemblePhase
            setHoursPrice={setHoursPrice}
            hoursPrice={hoursPrice}
            hoursValue={hoursValue}
            sethoursValue={sethoursValue}
          />
        </S.PurchasePhaseTwoWrapper>
        <S.PurchasePhaseThreeWrapper phase={phase}>
          <SubscriptionSummary
            storagePrice={storagePrice}
            hoursPrice={hoursPrice}
            storageValue={storageValue}
            hoursValue={hoursValue}
          />
        </S.PurchasePhaseThreeWrapper>
      </S.AssembleWrapper>
      {disableButton ? <S.AlreadyHaveText>Você já possui personal ativo!</S.AlreadyHaveText> : []}
      <S.FooterWrapper>
        <PersonalPlanCardButton
          title="voltar"
          onClick={() => {
            handlePreviousPhase();
          }}
          disabled={phase === 0 || disable}
        >
          voltar
        </PersonalPlanCardButton>
        <S.TotalPriceWrapper>
          <S.PriceText>Valor Total Assinatura</S.PriceText>
          <S.PriceValue>R${getTotalValue()}<S.PriceText>/mês</S.PriceText></S.PriceValue>
        </S.TotalPriceWrapper>
        <PersonalPlanCardButton
          title={getButtonText()}
          onClick={() => {
            getOnClickButtonFunction();
          }}
          disabled={
            (soldOff && phase === 0 && !(recurrency?.recurrencyStatus === 'ACTIVE')) ||
            (recurrency?.recurrencyStatus === 'PENDING' && !recurrency?.initPoint) ||
            disable
          }
          onWaiting={recurrency?.recurrencyStatus === 'PENDING'}
        >
          {getButtonText()}
        </PersonalPlanCardButton>
      </S.FooterWrapper>
      {recurrency?.recurrencyStatus === 'PENDING' && recurrency?.initPoint && (
        <S.P
          onClick={() => {
            onClickRemakePlan();
          }}
        >
          Refazer plano
        </S.P>
      )}
    </S.ContentWrapper>
  );
};

export default AssembleComputerWrapper;
