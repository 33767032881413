import React, { useState } from 'react';
import Cookies from 'js-cookie';
import * as jwtUtil from '@Utils/jwt';
import * as NoarAPI from '@Services/noar-api';
import { SESSION_COOKIE_NAME, REDIRECT_AFTER_LOGIN, REDIRECT_AFTER_REGISTER } from '@Constants';
import Form from './Form';
import Input from './Input';
import Submit from './Submit';
import Footer from './Footer';
import ErrorMessage from './ErrorMessage';
import InputAdornment from '@material-ui/core/InputAdornment';
import VisibilityOutlinedIcon from '@material-ui/icons/VisibilityOutlined';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import IconButton from '@material-ui/core/IconButton';
import { useHistory } from 'react-router-dom';
import Overlay from '@Containers/Overlay';
import Register from './Register';
import InputUserImg from '../../../../assets/user-icon.svg';
import InputPasswordImg from '../../../../assets/password-input-icon.svg';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';

export const Content = () => {
  const [email, handleEmail] = useState('');
  const [password, handlePassword] = useState('');
  const [showPassword, handleShowPassword] = useState(false);
  const [errorLoginMessage, handleErrorLoginMessage] = useState('');
  const [loading, handleLoading] = useState(false);
  const history = useHistory();
  const { executeRecaptcha } = useGoogleReCaptcha();


  // ASYNC FUNCTIONS

  async function login() {
    try {
      handleLoading(true);
      const recaptchaAction = 'login';
      await executeRecaptcha(recaptchaAction).then(async (token) => {
        await NoarAPI.login(email, password, token).then(responseBody => {
          if (responseBody.challenge_name) {
            if (responseBody.challenge_name === 'VALIDATE_ACCOUNT' || responseBody.challenge_name === 'NEW_PASSWORD_REQUIRED') {
              history.push({ pathname: REDIRECT_AFTER_REGISTER, state: email, challenge: responseBody.challenge_name, challenge_session: responseBody.challenge_session });
              return;
            }
            else {
              handleLoading(false);
              handleErrorLoginMessage('Erro ao realizar login');
              resetErrorMessage();
              return;
            }
          }

          const clientInfoJwt = responseBody.id_token;
          const accessJwt = responseBody.access_token;
          const clientType = responseBody.client_type;
          const firstAccess = responseBody.first_access;
          const earlyAccess = responseBody.early_access;

          const clientInfoJwtPayload = jwtUtil.getPayload(clientInfoJwt);

          const cookieData = {
            jwt: accessJwt,
            clientType,
            client: {
              name: encodeURIComponent(clientInfoJwtPayload.name), // Encoding to old browsers that not supports utf8 on cookies 
            },
            first_access: firstAccess,
            early_access: earlyAccess,
          };

          Cookies.set(SESSION_COOKIE_NAME, JSON.stringify(cookieData), { expires: 1 });

        }).then(async () => {
          const activePlans = await NoarAPI.getActivatedPlans();
          const cookieData = JSON.parse(Cookies.get(SESSION_COOKIE_NAME));
          cookieData.activePlans = activePlans;
          Cookies.set(SESSION_COOKIE_NAME, JSON.stringify(cookieData), { expires: 1 });
          localStorage.setItem('hasAlreadyShowPersonalPaymentPopup', JSON.stringify(false));
          handleLoading(false);
          redirect();
        });
      });
    } catch (error) {
      handleLoading(false);
      handleErrorLoginMessage(error.message);
      resetErrorMessage();
    }
  }

  const redirect = () => {
    const defaultRoute = REDIRECT_AFTER_LOGIN;
    const { next = defaultRoute, state = {} } = history.location.state || {};
    const { formattedProducts } = state;
    history.push(next, { formattedProducts });
  }

  function onChangeEmail(event) {
    handleEmail(event.target.value);
  }

  function onChangePassword(event) {
    handlePassword(event.target.value);
  }

  function onClickShowPassword() {
    handleShowPassword(!showPassword);
  }

  function onSubmitLoginForm(ev) {
    ev.preventDefault();
    login();
  }

  function resetErrorMessage() {
    setTimeout(function () {
      handleErrorLoginMessage('');
    }, 5000);
  }

  return (
    <>
      {loading ? <Overlay /> : []}
      <Form onSubmit={onSubmitLoginForm}>
        <Input
          type="email"
          name="email"
          placeholder="Digite seu e-mail"
          required
          value={email}
          onChange={onChangeEmail}
          maxLength="255"
          autoComplete="on"
          variant="outlined"
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <img src={InputUserImg} alt="" />
              </InputAdornment>
            ),
          }}
        />
        <Input
          type={showPassword ? 'text' : 'password'}
          name="password"
          placeholder="Digite sua senha"
          required
          value={password}
          onChange={onChangePassword}
          minLength="8"
          maxLength="64"
          autoComplete="on"
          variant="outlined"
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton aria-label="showPassword" component="a" onClick={onClickShowPassword}>
                  {showPassword ? (
                    <VisibilityOutlinedIcon />
                  ) : (
                    <img src={InputPasswordImg} alt="" style={{ width: '24px', margin: 0 }} />
                  )}
                </IconButton>
              </InputAdornment>
            ),
            startAdornment: (
              <InputAdornment position="start">
                <LockOutlinedIcon style={{ color: '#426FE5' }} />
              </InputAdornment>
            ),
          }}
        />
        {errorLoginMessage ? <ErrorMessage>{errorLoginMessage}</ErrorMessage> : []}
        <Submit />
        <Register />
        <Footer />
      </Form>
    </>
  );
};

export default Content;
