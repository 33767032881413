import styled from 'styled-components';

import { desktopMinSize, mqmin } from '@Styles/mixins';
import { mq } from '../../styles/mixins';

export const Text = styled.p`
  --fontSize: 13;

  font-size: calc(var(--fontSize) * 1rem / 16);
  line-height: calc(19 / var(--fontSize));
  text-align: center;

  ${mqmin(desktopMinSize)} {
    --fontSize: 18;

    line-height: calc(28 / var(--fontSize));
    text-align: start;
  }

  ${mq(1439, desktopMinSize)} {
    --fontSize: 12;
    line-height: calc(19 / var(--fontSize));
    text-align: start;  
  }

  .uppercase {
    text-transform: uppercase;
  }

  em {
    color: transparent;
    background: linear-gradient(90deg, #2d80f2 0%, #a00bf6 100%);
    -webkit-background-clip: text;
    background-clip: text;
  }
`;

export default Text;
