import * as S from './styled';
import { Title, Text } from '@Components/Public';
import select from '../HowItWorks/icons/select.png';
import lendoumlivro from '../HowItWorks/icons/lendoumlivro.png';
import alunograduacao from '../HowItWorks/icons/alunograduacao.png';
import computacaoemnuvem from '../HowItWorks/icons/computacaoemnuvem.png';
import freetag from '../HowItWorks/icons/free.png';
import { Button } from '../../../components/Public';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import PlanForm from './PlanForm';
import { useState } from 'react';
import { ListContent } from './PlanComparison/styled';

const Plans = () => {
  const [showPlanForm, setShowPlanForm] = useState(false);
  const history = useHistory();

  function goToRegister() {
    history.push('/register');
  }

  function handleOrder(id, quantity) {
    const product = [
      {
        product_id: id,
        quantity,
      },
    ];

    handlePublicRedirect(product);
    return;
  }

  const handlePublicRedirect = formattedProducts => {
    history.push('/login', { next: '/shop/unit', state: { formattedProducts: formattedProducts } });
  };

  return (
    <S.PlansWrapper id="planos">
      <S.Main>
        <Title as="h2">
          <strong>Nossos Planos</strong>
        </Title>
        <S.Main>
          <S.PlanInfoWrapper>
            <S.PlanInfoTextWrapper>
              <p><strong>Já pensou em ter um supercomputador o <em>mês inteiro</em>?</strong></p><br />
              <ListContent>
                <Text>• Nosso plano unit é uma assinatura mensal onde você ganha acesso a um computador COMPLETO!</Text>
                <Text>• Você pode instalar seus programas favoritos, logar nas suas contas, jogar, trabalhar...</Text>
                <Text><p><strong><em>Literalmente fazer o que quiser!</em></strong></p></Text>
              </ListContent>
            </S.PlanInfoTextWrapper>
            <S.PlanInfoTextWrapper>
              <p><strong>Funciona assim:</strong></p><br />
              <ListContent>
                <Text>• Você escolhe a sua mensalidade.</Text>
                <Text>• Baixa nosso aplicativo.</Text>
                <Text>• Aperta Play.</Text>
                <Text>• E pronto, seu computador está pronto para o uso!</Text>
              </ListContent>
            </S.PlanInfoTextWrapper>
            <S.PlanInfoTextWrapper>
              <p><strong>Nossos computadores</strong></p><br />
              <ListContent>
                <Text>• Ryzen de 3 a 8 vCPUs.</Text>
                <Text>• Memoria DDR4 de 8GB a 20GB.</Text>
                <Text>• Nvme.</Text>
                <Text>• GPU Nvidia 40 Series (4070, 4080, 4090, ou AMD equivalente)</Text>
                <br/>
                <S.Observation>
                  <p>Para casos em que precisar renderizar algo, é necessário reservar um servidor.<br/>(Ryzen 5900XT, 128GB DDR4, 500GB NVME, 4090 24GB)</p>
                </S.Observation>
              </ListContent>
            </S.PlanInfoTextWrapper>
          </S.PlanInfoWrapper>
          <Text>
            <strong><em>Sim, o UNIT está de volta!<br />E melhor do que nunca!</em></strong>
          </Text>
        </S.Main>
      </S.Main >
      <S.ChooseWrapper>
        <S.NichePresentation>
          <S.HeaderWrapper>
            <S.NicheIconsWrapper>
              <S.NicheImage src={freetag} alt="Uma tag escrito free." />
            </S.NicheIconsWrapper>
            <S.NichePresentationTitle>
              <strong>Fila Gratuita</strong>
            </S.NichePresentationTitle>
          </S.HeaderWrapper>
          <S.ChecklistWrapper>
            <p>Utilize de graça!</p><br />
            <S.ChecklistItem>
              <S.ChecklistItemImage src={select} alt="Select marcado." />
              <p>Acesse super computadores de forma gratuita.</p>
            </S.ChecklistItem>
            <S.ChecklistItem>
              <S.ChecklistItemImage src={select} alt="Select marcado." />
              <p>Até 30 minutos de sessão.</p>
            </S.ChecklistItem>
            <S.ChecklistItem>
              <S.ChecklistItemImage src={select} alt="Select marcado." />
              <p>Quer testar a tecnologia?</p>
            </S.ChecklistItem>
          </S.ChecklistWrapper>
          <S.NichePresentationSubTitle>
            <p>
              Utilize nossos supercomputadores de forma gratuita por até 30 minutos. Ideal para quem quer testar a tecnologia e entender como funciona.
            </p>
          </S.NichePresentationSubTitle>
          <Button variant="contained" home_modal={true} onClick={goToRegister}>
            Cadastrar-se
          </Button>
        </S.NichePresentation>
        <S.NichePresentation>
          <S.HeaderWrapper>
            <S.NicheIconsWrapper>
              <S.NicheImage src={alunograduacao} alt="" />
            </S.NicheIconsWrapper>
            <S.NichePresentationTitle>
              <strong>Basic</strong>
            </S.NichePresentationTitle>
          </S.HeaderWrapper>
          <S.ChecklistWrapper>
            <p>O plano mais simples!</p><br />
            <S.ChecklistItem>
              <S.ChecklistItemImage src={select} alt="Select marcado." />
              <p><strong>20 horas</strong> prioritárias totais.*</p>
            </S.ChecklistItem>
            <S.ChecklistItem>
              <S.ChecklistItemImage src={select} alt="Select marcado." />
              <p>Acesso garantido antes do Gratuito!</p>
            </S.ChecklistItem>
            <S.ChecklistItem>
              <S.ChecklistItemImage src={select} alt="Select marcado." />
              <p>3 horas de sessão.</p>
            </S.ChecklistItem>
            <S.ChecklistItem>
              <S.ChecklistItemImage src={select} alt="Select marcado." />
              <p>2 horas de sessão pós prioridade.</p>
            </S.ChecklistItem>
          </S.ChecklistWrapper>
          <Text>
            <S.PriceText>
              <strong><em>R$89,90 <a>/mês</a></em></strong>
            </S.PriceText>
          </Text>
          {/* TODO: FAZER BOTAO LEVAR PARA LOJA INTERNA */}
          <Button variant="contained" home_modal={true} onClick={() => handleOrder(49, 1)}>
            Assinar
          </Button>
        </S.NichePresentation>
        <S.NichePresentation>
          <S.HeaderWrapper>
            <S.NicheIconsWrapper>
              <S.NicheImage src={lendoumlivro} alt="Pessoa lendo um livro." />
            </S.NicheIconsWrapper>
            <S.NichePresentationTitle>
              <strong>Plus</strong>
            </S.NichePresentationTitle>
          </S.HeaderWrapper>
          <S.ChecklistWrapper>
            <p>O melhor custo benefício!</p><br />
            <S.ChecklistItem>
              <S.ChecklistItemImage src={select} alt="Select marcado." />
              <p><strong>40 horas</strong> prioritárias totais.*</p>
            </S.ChecklistItem>
            <S.ChecklistItem>
              <S.ChecklistItemImage src={select} alt="Select marcado." />
              <p>Acesso preferencial, acima do plano Basic.</p>
            </S.ChecklistItem>
            <S.ChecklistItem>
              <S.ChecklistItemImage src={select} alt="Select marcado." />
              <p>4 horas de sessão.</p>
            </S.ChecklistItem>
            <S.ChecklistItem>
              <S.ChecklistItemImage src={select} alt="Select marcado." />
              <p>2 horas de sessão pós prioridade.</p>
            </S.ChecklistItem>
          </S.ChecklistWrapper>
          <Text>
            <S.PriceText>
              <strong><em>R$129,90 <a>/mês</a></em></strong>
            </S.PriceText>
          </Text>
          {/* TODO: FAZER BOTAO LEVAR PARA LOJA INTERNA */}
          <Button variant="contained" home_modal={true} onClick={() => handleOrder(50, 1)}>
            Assinar
          </Button>
        </S.NichePresentation>
        <S.NichePresentation>
          <S.HeaderWrapper>
            <S.NicheIconsWrapper>
              <S.NicheImage src={computacaoemnuvem} alt="" />
            </S.NicheIconsWrapper>
            <S.NichePresentationTitle>
              <strong>ULTRA</strong>
            </S.NichePresentationTitle>
          </S.HeaderWrapper>
          <S.ChecklistWrapper>
            <p>O seu melhor plano!</p><br />
            <S.ChecklistItem>
              <S.ChecklistItemImage src={select} alt="Select marcado." />
              <p><strong>60 horas</strong> prioritárias totais.*</p>
            </S.ChecklistItem>
            <S.ChecklistItem>
              <S.ChecklistItemImage src={select} alt="Select marcado." />
              <p>Acesso exclusivo com máxima prioridade!</p>
            </S.ChecklistItem>
            <S.ChecklistItem>
              <S.ChecklistItemImage src={select} alt="Select marcado." />
              <p>5 horas de sessão.</p>
            </S.ChecklistItem>
            <S.ChecklistItem>
              <S.ChecklistItemImage src={select} alt="Select marcado." />
              <p>2 horas de sessão pós prioridade.</p>
            </S.ChecklistItem>
          </S.ChecklistWrapper>
          <Text>
            <S.PriceText>
              <strong><em>R$169,90 <a>/mês</a></em></strong>
            </S.PriceText>
          </Text>
          {/* TODO: FAZER BOTAO LEVAR PARA LOJA INTERNA */}
          <Button variant="contained" home_modal={true} onClick={() => handleOrder(51, 1)}>
            Assinar
          </Button>
        </S.NichePresentation>
      </S.ChooseWrapper>
      <S.Main>
        <S.Observation>
          <p>*Depois que as horas prioritárias acabarem, você ainda pode utilizar o UNIT, mas com menos prioridade.</p>
          <p><strong>Prioridade na fila:</strong> Gratuito ➔ Basic ➔ Plus ➔ Ultra ➔ Basic Prioritário ➔ Plus Prioritário ➔ Ultra Prioritário</p>
        </S.Observation>
      </S.Main>
    </S.PlansWrapper >
  );
};

export default Plans;
