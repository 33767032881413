import React, { useEffect, useRef, useState } from 'react';
import {
  ClassicPlanCard,
  ClassicPlanCardTitle,
  ClassicPlanCardInfo,
  ClassicPlanCardPrice,
  ClassicPlanCardKnowMoreButton,
  VerticalDivider,
  MobileVerticalDivider,
  MobileDividerWrapper,
  ClassicPlanCardWrapper,
  ClassicPlanCardOptionsWrapper,
  ClassicPlanCardPrices,
  ClassicPlanCardPriceTitle,
  ClassicPlanCardPriceButtons,
  AboutStardardWrapper,
  Divider,
  MobileDivider,
  PriceDiscountDiv,
  MainInfoWrapper,
  ColoredSpan,
  ActualPriceP,
  OriginalPriceP,
} from './styled';
import { MdOutlineClose } from 'react-icons/md';
import { useShoppingCart } from '../../../../context/ShoppingCartContext';
import { formatCurrency } from '../../../../utils/formatCurrency';
import Toast from '@Toast/Toast';
import '@Toast/styles.css';
import { useTutorialStep } from '../../../../context/TutorialStepContext';
import { desktopMinSize } from '@Styles/mixins';
import { ContractDivWrapper, InfoDivWrapper } from '../Personal/styled';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';

export const ClassicCard = ({
  products,
  isPublic,
  setIsMobileCartOpen,
}) => {
  const { handleIncreaseItemQuantity, cleanCart } = useShoppingCart();
  const { state } = useTutorialStep();
  const history = useHistory();

  const modalRef = useRef();
  

  function handleAddToCart(id) {
    const toast = document.querySelector('.toast');
    if (toast) {
      toast.remove();
    }
    new Toast({
      text: `<div class="toast-content"><svg id="Done" xmlns="http://www.w3.org/2000/svg" width="24.119" height="24.119" viewBox="0 0 24.119 24.119">
      <path id="Caminho_38" data-name="Caminho 38" d="M107.917,23.85a1.046,1.046,0,0,0-2.056.381,10.588,10.588,0,0,1,.178,1.8,9.978,9.978,0,1,1-9.978-9.952,9.664,9.664,0,0,1,3.884.787,1.047,1.047,0,1,0,.812-1.929,12.4,12.4,0,0,0-4.7-.94A12.059,12.059,0,1,0,108.12,26.058a12.532,12.532,0,0,0-.2-2.209Z" transform="translate(-84.001 -13.999)" fill="#f7ffff"/>
      <path id="Caminho_39" data-name="Caminho 39" d="M219.315,59.612c-1.244-1.8-4.215-.076-2.945,1.726,1.523,2.183,2.92,4.468,4.443,6.652a1.734,1.734,0,0,0,2.945,0,73.359,73.359,0,0,1,9.394-12.745c1.523-1.574-.889-4.011-2.412-2.412A69.767,69.767,0,0,0,222.235,64c-.965-1.472-1.9-2.945-2.92-4.392Z" transform="translate(-210.074 -50.574)" fill="#f7ffff"/>
      </svg>
      <div>
      <strong>Sucesso</strong>
      <p>Adicionado ao carrinho</p>
      </div>
    </div>`,
      canClose: true,
      pauseOnHover: false,
      pauseOnFocusLoss: false,
      closeOnClick: true,
    });
    handleIncreaseItemQuantity(id);
  }

  function handleOrder(id, quantity) {
    const product = [
      {
        product_id: id,
        quantity,
      },
    ];

    if (isPublic) {
      handlePublicRedirect(product);
      return;
    }

    cleanCart();
    handleIncreaseItemQuantity(id);
    setIsMobileCartOpen(true);
  }

  const handleKnowMoreOnClassicOpenModal = () => {
    const modal = modalRef.current;
    modal.showModal();
  };

  const handleKnowMoreOnClassicCloseModal = () => {
    const modal = modalRef.current;

    modal.setAttribute('closing', '');
    modal.addEventListener(
      'animationend',
      () => {
        modal.removeAttribute('closing');
        modal.close();
      },
      { once: true },
    );
  };

  const handlePublicRedirect = formattedProducts => {
    history.push('/login', { next: '/shop/hours', state: { formattedProducts: formattedProducts } });
  };

  useEffect(() => {
    
    if (history.location.state?.formattedProducts) {
      cleanCart();
      history.location.state.formattedProducts.forEach(product => {
        handleAddToCart(product.product_id);
      });
      setIsMobileCartOpen(true);
    }
  }, []);

  return (
    <>
      <ClassicPlanCard step={state[3].step} isOnClassic={true}>
        <ClassicPlanCardWrapper>
          <InfoDivWrapper>
            <ClassicPlanCardTitle>Utilize seu computador!</ClassicPlanCardTitle>
            <Divider />
            <ClassicPlanCardInfo>
              {desktopMinSize >= window.innerWidth ? (
                <>
                  <li>
                    • Plano com pacotes
                    <br /> de horas, ou horas avulsas;
                  </li>
                  <li>• Sem armazenamento incluso;</li>
                  <li>
                    • Gerencie o tempo de
                    <br />
                    sua sessão;
                  </li>
                  <li>• Sem spot &#40;desligamentos&#41;.</li>
                </>
              ) : (
                <>
                  <li>
                    • Plano com pacotes
                    <br /> de horas, ou horas avulsas;
                  </li>
                  <li>• Sem armazenamento incluso;</li>
                  <li>• Gerencie o tempo de sua sessão;</li>
                  <li>• Sem spot.</li>
                </>
              )}
            </ClassicPlanCardInfo>
            <Divider />
          </InfoDivWrapper>
          <MobileVerticalDivider />
          <ContractDivWrapper>
            <ClassicPlanCardPrice>
              A partir de <span>{products && formatCurrency(products[0]?.price)}</span>
            </ClassicPlanCardPrice>
            <ClassicPlanCardKnowMoreButton onClick={handleKnowMoreOnClassicOpenModal}>
              Saiba Mais
            </ClassicPlanCardKnowMoreButton>
          </ContractDivWrapper>
        </ClassicPlanCardWrapper>

        <VerticalDivider />
        <MobileDividerWrapper>
          <MobileDivider />
        </MobileDividerWrapper>

        <ClassicPlanCardOptionsWrapper isOnClassic={true}>
          {products?.map(product => (
            <ClassicPlanCardPrices key={product.product_id}>
              <ClassicPlanCardPriceTitle>
                <MainInfoWrapper>
                  <strong>{product.amount_hours}h</strong>
                  <ColoredSpan>
                    <b>{product.discount !== 0 ? `${product.discount}% OFF` : []}</b>
                  </ColoredSpan>
                </MainInfoWrapper>
                <PriceDiscountDiv>
                  <ActualPriceP>
                    <b>{formatCurrency(product.price)}</b>
                  </ActualPriceP>
                  {product.price !== product.original_price && (
                    <OriginalPriceP>
                      <b>{formatCurrency(product.original_price)}</b>
                    </OriginalPriceP>
                  )}
                </PriceDiscountDiv>
              </ClassicPlanCardPriceTitle>
              <ClassicPlanCardPriceButtons>
                <button onClick={() => handleOrder(product?.product_id, 1)}>Comprar</button>
                <button onClick={() => handleAddToCart(product?.product_id)} hidden={isPublic}>
                  Adicionar ao carrinho
                </button>
              </ClassicPlanCardPriceButtons>
            </ClassicPlanCardPrices>
          ))}
        </ClassicPlanCardOptionsWrapper>

        <AboutStardardWrapper ref={modalRef}>
          <div>
            <strong>Sobre o Classic</strong>
            <ul>
              <li>• Plano padrão, com base em horas de uso;</li>
              <li>• Compre de forma avulsa e pacotes de horas para obter mais descontos;</li>
              <li>
                • Sessão sem limite de tempo. Fique conectado e com a máquina ligada o tempo que quiser, basta
                recarregar;
              </li>
              <li>• Spot OFF, sem desligamentos automáticos;</li>
              <li>
                • Configurações dos computadores padronizadas. Cada computador tem uma definição padrão de acordo com o
                aplicativo;
              </li>
              <li>• Resolução HD. Posteriormente será possível a utilização de UHD em um plano futuro;</li>
              <li>
                • Sempre que desligar, todos os arquivos serão excluídos, todos os programas e arquivos baixados na
                máquina são apagados quando a máquina é desligada;
              </li>
              <li>
                • Licenças dos programas não incluídas. A NOAR disponibiliza o computador, não o programa, para acessar
                alguns jogos ou programas pré-instalados, você precisa obter a licença dele.
              </li>
            </ul>
            <button ref={modalRef} onClick={handleKnowMoreOnClassicCloseModal}>
              <MdOutlineClose color="#fff" />
            </button>
          </div>
        </AboutStardardWrapper>
      </ClassicPlanCard>
    </>
  );
};
