import React from 'react';
import { Payment, initMercadoPago } from '@mercadopago/sdk-react';
import { processPayment } from '../../../../services/noar-api';
import { useDispatch } from 'react-redux';
import { openConfirmationLightbox } from '../../../../store/ducks/global/actions';
import { MP_PUBLIC_KEY } from '@Constants';

initMercadoPago(MP_PUBLIC_KEY);
function PaymentComponent({
  amount,
  preferenceId,
  selectedProductList,
  setPaymentId,
  handleShowPayment,
  onlyPix,
  onlyCard,
  setDisablePaymentContent,
  recurrent,
  discountVoucher,
}) {
  var dispatch = useDispatch();
  const messageError = {
    message: 'Ocorreu um erro ao gerar o pedido, por favor informe o código a seguir para o suporte: ',
    confirmBtnText: 'Fechar',
    refuseBtnText: '',
  };

  const initialization = {
    amount,
    preferenceId,
  };

  const paymentMethods = onlyPix
    ? {
      bankTransfer: 'all',
    }
    : onlyCard
      ? {
        creditCard: 'all',
        maxInstallments: recurrent ? 1 : 6,
      }
      : {
        bankTransfer: 'all',
        creditCard: 'all',
        maxInstallments: 6,
      };

  const customization = {
    paymentMethods: paymentMethods,
    visual: {
      style: {
        theme: 'dark',
      },
    },
  };

  const onSubmit = async ({ selectedPaymentMethod, formData }) => {
    setDisablePaymentContent(true);
    return new Promise((resolve, reject) => {
      processPayment(formData, selectedProductList, recurrent, discountVoucher)
        .then(async response => {
          const responseBody = await response.json();
          if (responseBody.status) {
            if (responseBody.status === 422) {
              handleShowPayment(false);
              dispatch(
                openConfirmationLightbox(
                  () => {
                    window.location.reload();
                  },
                  "Seu pagamento não foi aprovado, por favor valide os dados do cartão e tente novamente ou entre em contato com o suporte.",
                  messageError.confirmBtnText,
                  messageError.refuseBtnText,
                  null,
                  null,
                  false,
                ),
              );
              setDisablePaymentContent(false);
            } else {
              throw new Error('#HTP!200');
            }
          }
          
          setPaymentId(responseBody?.id_payment);
          setDisablePaymentContent(false);
          resolve();
        })
        .catch(error => {
          handleShowPayment(false);
          dispatch(
            openConfirmationLightbox(
              () => {
                window.location.reload();
              },
              messageError.message + error?.message,
              messageError.confirmBtnText,
              messageError.refuseBtnText,
              null,
              null,
              false,
            ),
          );
          setDisablePaymentContent(false);
          reject();
        });
    });
  };

  const onError = async error => {
    setDisablePaymentContent(false);
    handleShowPayment(false);
    dispatch(
      openConfirmationLightbox(
        () => {
          window.location.reload();
        },
        messageError.message + error,
        messageError.confirmBtnText,
        messageError.refuseBtnText,
        null,
        null,
        false,
      ),
    );
  };

  const onReady = async () => { };

  return (
    <Payment
      initialization={initialization}
      customization={customization}
      onSubmit={onSubmit}
      onReady={onReady}
      onError={onError}
    />
  );
}

export default PaymentComponent;
