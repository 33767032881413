import styled from 'styled-components';
import { desktopMinSize, mq, mqmax } from '../../../../styles/mixins';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 4rem;
  padding: calc(180rem / 16);

  ${mq(desktopMinSize + 400, desktopMinSize)} {
    padding: calc(50rem / 16);
  }

  ${mqmax(desktopMinSize - 1)} {
    flex-direction: column;
    gap: 5;
    padding: calc(15rem / 16) 1rem;
    max-width: 100%;
    align-items: center;
  }
`;

export const Header = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const Main = styled.div`
  display: flex;
  flex-direction: column;

  & > p {
    text-align: left;
    display: flex;
    flex-direction: column;
    row-gap: 10px;
  }
`;

export const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  row-gap: calc(40rem / 16);
  width: 100%;
  max-width: min(calc(324 / 360 * 100%), calc(1331rem / 16));
`;

export const GroupContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  row-gap: calc(20rem / 16);
  width: 100%;
`;

export const ListContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  gap: 0.1rem;
  & > p {
    text-align: left;
  }
`;
